<style scoped>
.home {
  background-color: rgb(241, 239, 240);
  height: 100%;
  overflow: auto;
  text-align: center;
}

/*.screen{width: calc(100% - 80px);display: inline-block;}*/
.screen :deep(.ivu-tabs-nav-scroll ){
  height: 60px;
  line-height: 60px;
}

/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav ){
  height: 60px;
  line-height: 60px;
}

.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active ){
  color: rgb(222, 79, 11);
  padding: 0px 16px;
}

.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover ){
  color: rgb(222, 79, 11);
}

.screen :deep(.ivu-tabs-ink-bar ){
  background-color: rgb(222, 79, 11);
}

.screen :deep(.ivu-tabs-bar ){
  border-bottom: 2px solid #d7d7d7;
  margin-bottom: 40px;
}

.house {
  width: 72px;
  height: 72px;
  margin-top: 29px;
}

.number {
  font-size: 36px;
  color: #7a7a7a;
}

.tipsName {
  font-size: 16px;
  color: #8d8d8d;
}

.displayData {
  width: 14.2%;
  height: 220px;
  background-color: #fff;
  font-size: 0;
  display: inline-block;
  text-align: center;
  /*margin-left: 2%;*/
}

.administration {
  width: 100%;
  background-color: #fff;
  margin-top: 40px;
  border-bottom: 1px solid #dddddd;
  height: 80px;
  text-align: left;
  margin-bottom: 0;
}

.administration p {
  font-size: 20px;
  color: #333333;
  display: inline-block;
  line-height: 80px;
}

.label {
  width: 6px;
  height: 24px;
  vertical-align: top;
  margin: 28px 20px 0 20px;
}

.forMtitle {
  width: calc(100% - 80px);
  background-color: #f9f9f9;
  margin: 40px 40px 0 40px;
  height: 60px;
}

.forMtitle p {
  display: inline-block;
  color: #333333;
  font-size: 16px;
  width: 20%;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
}

.forMcontent {
  width: calc(100% - 80px);
  height: 60px;
  color: #666666;
  font-size: 16px;
  margin: 0px 40px 0 40px;
  border-bottom: 1px solid #dddddd;
  text-align: center;
}

.forMcontent p {
  line-height: 60px;
  display: inline-block;
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
}

.smallProgram {
  width: 32px;
  height: 32px;
  vertical-align: top;
}

.alertCode {
  position: absolute;
  width: 124px;
  height: 150px;
  background-color: #fff;
  z-index: 99;
  text-align: center;
  top: -50px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  right: -23px;
}

.alertCode p {
  width: 100%;
  height: 26px;
  line-height: 26px;
  border-bottom: 1px solid #dddddd;
  color: #666666;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
}

.qrCode {
  width: 100px;
  height: 100px;
}

.addTo {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #00b86c;
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  float: right;
  line-height: 40px;
  margin: 20px 40px 0 0;
  cursor: pointer;
}

.myWord {
  font-size: 16px;
  color: #00b86c;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-bottom: 1px solid #dddddd;
}

.icon1 {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  margin-right: 20px;
  vertical-align: top;
  margin-top: 18px;
}

.contacts {
  font-size: 16px;
  color: #999999;
  display: inline-block;
  height: 66px;
  line-height: 66px;
}

.single {
  width: 100%;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  display: inline-block;
}

.inputWord {
  color: #333333;
  font-size: 16px;
  border: 0;
  text-align: left;
  height: 66px;
  margin-left: 20px;
  width: 350px;
}

/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>

<template>
  <div class="home screen">
    <Modal :z-index="1" :draggable="true" v-model="EvaPanel.state" title="评价详情">
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>评价</th>
            <th>评语</th>
            <th>咨询类型</th>
            <th>咨询结束时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in EvaPanel.list">
            <td>{{ value.UserName }}</td>
            <td>
              <span v-if="value.OrderGrade == 5">非常满意</span>
              <span v-else-if="value.OrderGrade == 4">满意</span>
              <span v-else-if="value.OrderGrade == 3">基本满意</span>
              <span v-else-if="value.OrderGrade == 2">不满意</span>
              <span v-else-if="value.OrderGrade == 1">非常不满意</span>
              <span v-else>--</span>
            </td>
            <td>{{ value.OrderEvaluate }}</td>
            <td>{{ value.OrderTypeTitle }}</td>
            <td>{{ value.OrderEndTime || value.LawyerOrderEndTime }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <Page
          :total="EvaPanel.count"
          :page-size="EvaPanel.pageSize"
          show-elevator
          show-total
          @on-change="
                    EvaPanel.page = $event
                    getEva(getEvaLawyerId)
                "
      />
    </Modal>
    <!--<HelloWorld>-->
    <Modal v-model="SPC.state" draggable>
      <!--phoneNum:'',
            lawyerName:'',
            vallageName:'',
            src:'', -->
      <canvas id="springCanvas" width="1063" height="638" style="display: none"></canvas>
      <div style="text-align: center">
        <Button type="primary" @click="downloadCard">下载便民卡</Button>
      </div>
      <div style="text-align: center">
        <div style="padding: 15px"></div>
        <div style="font-size: 36px">{{ SPC.areaName }} - {{ SPC.VNName }}</div>
        <div style="padding: 15px"></div>
        <div id="qrcode" ref="qrcode" style="display: inline-block;"></div>
        <img v-if="SPC.src" :src="SPC.src" />
        <div style="font-size: 30px">村（社区）法律顾问：{{ SPC.lawyerName }}</div>
        <div style="font-size: 30px">{{ SPC.phoneNum }}</div>
      </div>
    </Modal>
    <!--</HelloWorld>-->
    <div style="background-color: rgb(241, 239, 240)">
      <!--<div class="screen">-->
      <!--<div>本月 </div>-->
      <!--</div>-->
      <div style="background-color: white">
        <div style="padding: 15px"></div>
        <div style="text-align: left; padding: 0 20px">
          <div
              style="
                            font-size: 16px;
                            border-left: solid #de4f0b 3px;
                            padding-left: 5px;
                            display: inline-block;
                        "
          >
            总统计数
          </div>
          <div style="font-size: 16px; text-align: left; margin: 10px 0 0 20px; display: inline-block">
            <DatePicker
                :value="timeData"
                v-model="timeData"
                :transfer="transfer"
                type="daterange"
                placement="bottom-end"
                placeholder="选择时间"
                style="width: 200px"
                @on-change="totalTime"
            ></DatePicker>
          </div>
          <div style="padding: 5px"></div>
          <div  style="display: inline-block">
            本月值班打卡：<span style="color: red">{{ monthAssessment.ClockNum }}</span
          >人次
            <span style="color: #e5e5e5; margin: 0 7px">|</span>
            本月值班时长：<span style="color: red"
          >{{ parseInt(monthAssessment.ServiceTime / 60) }}小时{{
              monthAssessment.ServiceTime % 60
            }}分钟</span
          >
            <span style="color: #e5e5e5; margin: 0 7px">|</span>
            本月上传：<span style="color: red">{{ monthAssessment.FilesCount }}</span
          >个电子档案
          </div>
          <div style="display: inline-block" v-if="fileTotalMonthData">
            <span style="color: #e5e5e5; margin: 0 7px">|</span>
            本月服务时长
            <span style="color: red">
                      {{ parseInt(fileTotalMonthData / 60) }}小时{{fileTotalMonthData % 60}}分钟
                    </span>
          </div>
        </div>
        <Divider />
        <div style="text-align: left">
          <div
              class="displayData"
              @click="numberofStates()"
              style="vertical-align: top"
              v-if="information.adminLevel == 0 || information.adminLevel == 1 || information.adminLevel == 2"
          >
            <!--<img class="house" :src="/img/house.png">-->
            <img class="house" src="/img/house10.png" />
            <p class="number">{{ countData || '--' }}<span style="font-size: 16px;margin-left: 3px" v-if="countData">个</span></p>
            <p class="tipsName" v-if="information.adminLevel == 0">省数</p>
            <p class="tipsName" v-if="information.adminLevel == 1">市州数</p>
            <p class="tipsName" v-if="information.Hierarchy == 2">
              <span v-if="information.AdminId == '275'">乡镇数</span>
              <span v-if="information.AdminId == '373'">街道（司法所）数</span>
              <span v-if="information.ParentId == '2237' || information.ParentId == '2262'"
              >街道（司法所）数</span
              >
              <span v-else-if="areaId == '2208' || areaId == '2225'">乡镇数</span>
              <span v-else>区县数</span>
            </p>
            <p class="tipsName" v-if="information.Hierarchy == 3">街道（司法所）数</p>
          </div>
          <div
              class="displayData"
              v-for="(value, index) in statisticsData"
              :key="index"
              @click="orderTypeCount(value.type)"
          >
            <!--<img class="house" :src="/img/house.png">-->
            <img class="house" :src="'/img/house' + '' + value.img + '.png'" />
            <p class="number">{{ value.value }}<span style="font-size: 16px;margin-left: 3px">{{value.unit}}</span></p>
            <p class="tipsName">{{ value.name }}</p>
          </div>
          <!--          <div class="displayData" v-for="(value,index) in statisticsData" :key="index"-->

          <!--            v-if="index == 3" @click="modal3 = true;orderTypeCount(value.type)">-->
          <!--            <img class="house" :src="'/img/house'+''+value.img+'.png'">-->
          <!--            <p class="number">{{value.value}}</p>-->
          <!--            <p class="tipsName">{{value.name}}</p>-->
          <!--          </div>-->
        </div>
        <!--<Tabs v-model="boxt" class="screen"  @on-click="switchData">-->
        <!--<TabPane :label="time.name"  v-for="(time,i) in timelist">-->
        <!---->

        <!--</TabPane>-->
        <!--</Tabs>-->
        <!--<div id="allDataView" style="height: 400px;"></div>-->
      </div>
      <!---->

      <div style="background-color: white; margin: 20px 0">
        <div style="padding: 15px"></div>
        <div
            style="
                        font-size: 16px;
                        text-align: left;
                        margin-left: 20px;
                        border-left: solid #de4f0b 3px;
                        padding-left: 5px;
                    "
        >
          十日新增数据
        </div>
        <div id="myChart1" style="height: 400px"></div>
        <div style="padding: 15px"></div>
      </div>
      <!---->
      <div style="background-color: white; margin: 20px 0" >
        <div style="padding: 15px"></div>
        <div
            style="
                        font-size: 16px;
                        text-align: left;
                        margin-left: 20px;
                        border-left: solid #de4f0b 3px;
                        padding-left: 5px;
                    "
        >
          昨日数据
        </div>
        <Row style="font-size: 14px; height: 180px" type="flex" justify="center" align="middle">
          <!-- <Col span="6" style="border-right: 1px solid rgb(209, 209, 209)">
                        <Row>
                            <Col>服务村（社区）</Col>
                        </Row>
                        <div style="padding: 10px"></div>
                        <Row>
                            <Col style="color: red">{{ yesterdayData.village }}</Col>
                        </Row>
                    </Col>
                    <Col span="6" style="border-right: 1px solid rgb(209, 209, 209)">
                        <Row>
                            <Col>服务律师</Col>
                        </Row>
                        <div style="padding: 10px"></div>
                        <Row>
                            <Col style="color: red">{{ yesterdayData.lawyer }}</Col>
                        </Row>
                    </Col>
                    <Col span="6" style="border-right: 1px solid rgb(209, 209, 209)">
                        <Row>
                            <Col>服务用户</Col>
                        </Row>
                        <div style="padding: 10px"></div>
                        <Row>
                            <Col style="color: red">{{ yesterdayData.user }}</Col>
                        </Row>
                    </Col>
                    <Col span="6">
                        <Row>
                            <Col>咨询次数</Col>
                        </Row>
                        <div style="padding: 10px"></div>
                        <Row>
                            <Col style="color: red">{{ yesterdayData.order }}</Col>
                        </Row>
                    </Col> -->
          <Col span="6">
            <div>
              <Col style="font-size: 24px">服务村（社区）</Col>
              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.village }}<span style="font-size: 16px; margin-left: 3px;">个</span></Col>
            </div>
          </Col>
          <Col span="6">
            <div>
              <Col style="font-size: 24px">服务律师</Col>
              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.lawyer }}<span style="font-size: 16px; margin-left: 3px;">人</span></Col>
            </div>
          </Col>
          <Col span="6">
            <div>
              <Col style="font-size: 24px">服务用户</Col>
              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.user }}<span style="font-size: 16px; margin-left: 3px;">人</span></Col>
            </div>
          </Col>
          <Col span="6">
            <div>
              <Col style="font-size: 24px">咨询次数</Col>
              <Col style="color: red; font-size: 24px; margin-top: 10px">{{ yesterdayData.order }}<span style="font-size: 16px; margin-left: 3px;">次</span></Col>
            </div>
          </Col>
        </Row>
      </div>
      <!---->
      <div style="background-color: white; margin: 20px 0">
        <div style="padding: 15px"></div>
        <div
            style="
                        font-size: 16px;
                        text-align: left;
                        margin-left: 20px;
                        border-left: solid #de4f0b 3px;
                        padding-left: 5px;
                    "
        >
          十日服务数据
        </div>
        <div id="myChartGetTen" :style="{ width: '100%', height: '600px' }"></div>
      </div>
      <!---->
      <!--            <div style="background-color: white; margin: 20px 0">-->
      <!--                <div style="padding: 15px"></div>-->
      <!--                <div style="position: relative">-->
      <!--                    <div-->
      <!--                        style="-->
      <!--                            font-size: 16px;-->
      <!--                            text-align: left;-->
      <!--                            margin-left: 20px;-->
      <!--                            border-left: solid #de4f0b 3px;-->
      <!--                            padding-left: 5px;-->
      <!--                        "-->
      <!--                    >-->
      <!--                        地图分布-->
      <!--                    </div>-->
      <!--                    <div id="mapChart" :style="{ width: '100%', height: '600px' }"></div>-->
      <!--                    <div-->
      <!--                        v-if="map.mapHistory.length > 1 && map.mapLodingState == false"-->
      <!--                        style="-->
      <!--                            cursor: pointer;-->
      <!--                            padding: 5px 15px;-->
      <!--                            position: absolute;-->
      <!--                            top: 80px;-->
      <!--                            left: 40px;-->
      <!--                            z-index: 999;-->
      <!--                            background-color: #29c8ff;-->
      <!--                            font-size: 14px;-->
      <!--                            color: #fff;-->
      <!--                            border-radius: 5px;-->
      <!--                        "-->
      <!--                        @click="backMap"-->
      <!--                    >-->
      <!--                        返回-->
      <!--                    </div>-->
      <!--                    <div-->
      <!--                        ref="childPanel"-->
      <!--                        style="-->
      <!--                            color: #fff;-->
      <!--                            font-size: 14px;-->
      <!--                            padding: 15px;-->
      <!--                            border-radius: 5px;-->
      <!--                            background-color: rgba(0, 0, 0, 0.5);-->
      <!--                            position: absolute;-->
      <!--                            display: none;-->
      <!--                        "-->
      <!--                    >-->
      <!--                        <div v-for="(item, index) in map.childData">{{ item.name }}:{{ item.value }}</div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <div style="padding: 15px"></div>-->
      <!--            </div>-->
      <!-- <div style="background-color: white;margin:20px;">
                 <div style="padding:15px"></div>
                 <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 用户属性</div>
                 <Row style="font-size:16px">
                     <Col span="12">
                         <div>性别分布</div>
                         <Row type="flex" justify="center" align="middle">
                             <Col span="14"><div style="height:400px" id="sexDistributed"></div></Col>
                             <Col span="10">
                                 <Row v-for="(item,index) in sexDistributedList" style="font-size:14px">
                                     <Col span="8" style="text-align: right">{{item.key}}</Col>
                                     <Col span="12" style="text-align: left">：{{item.value}}</Col>
                                 </Row>
                             </Col>
                         </Row>
                     </Col>
                     <Col span="12">
                         <div>年龄分布</div>
                         <div style="padding:10px;"></div>
                         <Row type="flex" justify="center" align="middle">
                             <Col span="16"><div style="height:400px" id="ageDistributed"></div></Col>
                             <Col span="8">
                                 <Row v-for="(item,index) in ageDistributedList" style="font-size:14px">
                                     <Col span="12" style="text-align: right">{{item.key}}</Col>
                                     <Col span="8" style="text-align: left">：{{item.value}}</Col>
                                 </Row>
                             </Col>
                         </Row>
                     </Col>
                 </Row>
                 <div style="padding:15px"></div>
             </div>
  -->
      <div style="background-color: white; margin: 20px" v-if="information.AdminId == 0">
        <div style="padding: 15px"></div>
        <div
            style="
                        font-size: 16px;
                        text-align: left;
                        margin-left: 20px;
                        border-left: solid #de4f0b 3px;
                        padding-left: 5px;
                    "
        >
          同时在线人数:{{ getOnlineNum }}
        </div>
        <Row>
          <Col span="12">
            <!--<Row  type="flex" justify="center" align="middle">-->
            <!--<Col span="12"><div style="height:400px;" id="devicesDistributed"></div></Col>-->
            <!--<Col span="12">-->
            <!--<Row v-for="(item,index) in equipment" style="font-size:14px">-->
            <!--<Col span="8" style="text-align: right">{{item.name}}</Col>-->
            <!--<Col span="12" style="text-align: left">：{{item.value}}</Col>-->
            <!--</Row>-->
            <!--</Col>-->
            <!--</Row>-->
          </Col>
          <Col span="12">
            <!--<Row  type="flex" justify="center" align="middle">-->
            <!--<div style="font-size: 16px" >同时在线人数:{{getOnlineNum}}</div>-->
            <!--</Row>-->
          </Col>
        </Row>
        <div style="padding: 15px"></div>
      </div>
      <!--律所管理律师列表-->
      <div v-if="information.adminLevel == 4">
        <div class="font-size" style="width: calc(100% - 80px); display: inline-block">
          <div class="administration">
            <img class="label" src="/img/label.png" />
            <p>管理</p>
            <div class="addTo" @click="modal1 = true">添加</div>
          </div>
          <div style="background-color: #fff; width: 100%; display: inline-block; margin-bottom: 40px">
            <div class="forMtitle" v-if="listData != []">
              <p>律师</p>
              <p>电话</p>
              <p>小程序码</p>
              <p>评分</p>
              <p>操作</p>
            </div>
            <!--for-->
            <div class="forMcontent" v-for="(value, index) in listData" :key="index">
              <p>{{ value.LawyerName }}</p>
              <p>{{ value.LawyerPhone }}</p>
              <!--<div style="position: relative;line-height: 60px;display: inline-block;width: 20%;vertical-align: top">-->
              <!--<img class="smallProgram" @click="alertbox(value.LawyerQRcode,value.LawyerName)" src="/img/smallProgram1.png">-->
              <!--<div >发送</div>-->
              <!--</div>-->
              <div
                  style="
                                    color: #00b86c;
                                    cursor: pointer;
                                    width: 20%;
                                    display: inline-block;
                                    line-height: 60px;
                                    vertical-align: top;
                                    overflow: hidden;
                                    white-space: nowrap;
                                "
              >
                <img
                    class="smallProgram"
                    @click="
                                        alertbox(
                                            value.LawyerQRcode,
                                            value.LawyerName,
                                            value.LawyerPhone,
                                            value.LawyerId
                                        )
                                    "
                    src="/img/smallProgram1.png"
                />
                <div style="display: inline-block; margin: 0 8%"></div>
                <Button
                    type="success"
                    @click="
                                        alertbox1(
                                            value.LawyerQRcode,
                                            value.LawyerName,
                                            value.LawyerId,
                                            value.LawyerPhone
                                        )
                                    "
                >发送</Button
                >
                <!--value.LawyerId,value.LawyerPhone-->
              </div>
              <p v-if="value.avgGrade">{{ value.avgGrade }}</p>
              <p v-else>暂无评分</p>
              <div
                  style="
                                    color: #00b86c;
                                    cursor: pointer;
                                    width: 20%;
                                    display: inline-block;
                                    line-height: 60px;
                                    vertical-align: top;
                                    overflow: hidden;
                                    white-space: nowrap;
                                "
              >
                <Button type="success" style="margin: 0 10px" @click="deleteLawyer(value.LawyerId)"
                >删除</Button
                >
                <Button
                    type="success"
                    style="margin: 0 10px"
                    @click="
                                        modal4 = true
                                        lawyerId = value.LawyerId
                                        lawyerName = value.LawyerName
                                        lawyerPhone = value.LawyerPhone
                                        OrganizationId = value.OrganizationId
                                        lawyerId = value.LawyerId
                                    "
                >更换律师</Button
                >
              </div>
              <!--<p style="color: #00b86c;cursor:pointer" @click="deleteLawyer(value.LawyerId)">删除</p>-->
            </div>
          </div>
        </div>
        <div style="text-align: left; margin-left: 40px; font-size: 16px">
          <!--<Page :total="count" page-size="5"  show-elevator />-->
          <!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
          <Page
              :total="count"
              :page-size="pageSize"
              show-elevator
              @on-change="
                            page = $event
                            lawList()
                        "
          />
        </div>
      </div>
      <!--管理律师列表-->
      <!--      <div v-if="information.adminLevel == 3">-->
      <!--        <div class="font-size" style="width: calc(100% - 80px);display: inline-block;">-->
      <!--          <div class="administration">-->
      <!--            <img class="label" src="/img/label.png">-->
      <!--            <p>管理</p>-->
      <!--            <div class="addTo" @click="modal2 = true">添加</div>-->
      <!--          </div>-->
      <!--          <div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;">-->
      <!--            <div class="forMtitle" v-if="listData != []">-->
      <!--              <p>机构名称</p>-->
      <!--              <p>电话</p>-->
      <!--              <p>登录名</p>-->
      <!--              <p>密码</p>-->
      <!--              <p>操作</p>-->
      <!--            </div>-->
      <!--            &lt;!&ndash;for&ndash;&gt;-->
      <!--            <div class="forMcontent" v-for="(value,index) in listData" :key="index">-->
      <!--              <p>{{value.OrganizationName}}</p>-->
      <!--              <p>{{value.OrganizationPhone}}</p>-->
      <!--              <p>{{value.AdminLogin}}</p>-->
      <!--              <div style="position: relative;line-height: 60px;display: inline-block;width: 20%">-->
      <!--                <p>{{value.AccountPassword}}</p>-->
      <!--                &lt;!&ndash;弹出&ndash;&gt;-->
      <!--                <div class="alertCode" v-if="arCode&&index==current">-->
      <!--                  &lt;!&ndash;&ndash;&gt;-->
      <!--                  <p>{{value.LawyerName}}</p>-->
      <!--                  <div style="width: 100%;position: absolute;top: 36px;">-->
      <!--                    <img class="qrCode" :src="value.LawyerQRcode">-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <p style="color: #00b86c;cursor:pointer" @click="deleteLawFirm(value.AdminId,value.OrganizationId)">删除</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div style="text-align: left;margin-left: 40px;font-size: 16px">-->
      <!--          &lt;!&ndash;<Page :total="count" page-size="5"  show-elevator />&ndash;&gt;-->
      <!--          &lt;!&ndash;<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>&ndash;&gt;-->
      <!--          <Page :total="count" show-total :page-size="pageSize" show-elevator @on-change="page = $event;lawList()" />-->
      <!--        </div>-->
      <!--      </div>-->
      <!---->
    </div>
    <!--弹出-->
    <Modal :draggable="true" v-model="modal1" title="添加" @on-ok="ok" @on-cancel="cancel">
      <div class="single">
        <p class="contacts">法律顾问</p>
        <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName" />
      </div>
      <div class="single">
        <p class="contacts">律师电话</p>
        <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone" />
      </div>
    </Modal>
    <!--更换律师-->
    <!-- <Modal :draggable="true" v-model="modal4" title="更换律师" @on-ok="replace()" @on-cancel="cancel">
            <div class="single">
                <p class="contacts">法律顾问</p>
                <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName" />
            </div>
            <div class="single">
                <p class="contacts">律师电话</p>
                <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone" />
            </div>
        </Modal> -->
    <!---->
    <!-- <Modal :draggable="true" :z-index="1" v-model="modal2" title="添加" @on-ok="addMember" @on-cancel="cancel">
            <div class="single">
                <p class="contacts">村居律所名称</p>
                <input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName" />
            </div>
            <div class="single">
                <p class="contacts">联系人</p>
                <input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman" />
            </div>
            <div class="single" v-if="cityList.length != 0">
                <p class="contacts">地址</p>
                <div style="display: inline-block">
                    <Select
                        v-model="model8"
                        style="width: 200px"
                        @on-change="choice"
                        :label-in-value="labelInValue"
                        :placeholder="tipsWord"
                    >
                        <Option
                            v-for="(item, index) in cityList"
                            :value="item.AreaId + ',' + index + ',' + '1'"
                            :label="item.AreaName"
                            :key="index"
                            >{{ item.AreaName }}</Option
                        >
                    </Select>
                    <Select
                        v-model="model9"
                        style="width: 200px"
                        @on-change="choice"
                        :label-in-value="labelInValue"
                        v-if="areaData"
                        :placeholder="tipsWord"
                    >
                        <Option
                            v-for="(i, index) in areaData"
                            :value="i.AreaId + ',' + index + ',' + '2'"
                            :label="i.AreaName"
                            :key="index"
                            >{{ i.AreaName }}</Option
                        >
                    </Select>
                </div>
            </div>
            <div class="single">
                <p class="contacts">联系电话</p>
                <input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone" />
            </div>
            <div class="single">
                <p class="contacts">登录名称</p>
                <input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin" />
            </div>
            <div class="single">
                <p class="contacts">登录密码</p>
                <input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword" />
            </div>
        </Modal> -->
    <serviceFrequency v-if="modal3" :modal3="modal3" :typeId="typeId" :startTime="startTime" :endTime="endTime" @message-updated="handleMessageUpdated"/>
    <!--服务社区（村）数量-->
    <Modal :draggable="true" v-model="modal10" title="村（社区）数" @on-ok="" @on-cancel="cancel" width="1200">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>村（社区）数</span>
        <span style="margin-left: 30px">共{{ count }}条</span>
      </p>
      <div class="publicWelfare">
        <Row>
          <Col span="12">
            <Input v-model="searchData" placeholder="搜索社区（村）名称" style="width: 300px" />
            <Button type="primary" @click="searchDara(1)">搜索社区</Button>
            <Button type="primary" @click="searchDara(2)">搜索律师</Button>
          </Col>
          <Col span="12" style="text-align: center">
            <Button type="primary" v-if="sort == 2" @click="sortClick('')">用户量(升序)</Button>
            <Button type="primary" v-else="" @click="sortClick(2)">用户量(降序)</Button>
          </Col>
        </Row>
        <!---->

        <!---->
        <table class="formtable">
          <thead>
          <tr>
            <th>法律顾问</th>
            <th>村（社区）</th>
            <th>法律服务所</th>
            <th>创建时间</th>
            <th>小程序码</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in adminVillagesData">
            <td nowrap="nowrap">
              <div
                  v-for="(item, i) in value.relation"
                  style="text-align: center; display: inline-block; margin: 0 5px"
              >
                <Avatar :src="item.lawyer.LawyerImg" />
                <div style="margin-top: 4px">{{ item.lawyer.LawyerName }}</div>
              </div>
            </td>
            <td >
              {{ value.CityName }}{{ value.adminname }}{{ value.VillagesName }}<samp style="padding: 10px"></samp>用户数量：（{{ value.total }}）
            </td>
            <td>
              <div v-for="(item, i) in value.relation">
                {{ item.lawyer.LawyerName }}：{{ item.lawyer.organization.OrganizationName }}
              </div>
            </td>
            <td>{{ value.CreatTime }}</td>
            <td style="text-align: center">
              <img @click="getSPC(value)" src="/img/smallProgram1.png" />
            </td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="count"
            show-total
            :page-size="pageSize"
            show-elevator
            @on-change="
                        page = $event
                        orderTypeCount(1, administration)
                    "
        />
      </div>
    </Modal>
    <!---->
    <Modal
        :draggable="true"
        v-model="modal16"
        title="服务村（社区）数"
        @on-ok=""
        @on-cancel="cancel"
        :z-index="1"
        width="900"
    >
      <p slot="header" style="color: #17233d; text-align: left">
        <span v-if="information.adminLevel == 0">省数</span>
        <span v-if="information.adminLevel == 1">市州数</span>
        <span v-if="information.Hierarchy == 2">
                    <span v-if="information.AdminId == '275'">乡镇数</span>
                    <span v-else-if="areaId == '2208' || areaId == '2225'">乡镇数</span>
                    <span v-else-if="information.ParentId == '2237' || information.ParentId == '2262'"
                    >街道（司法所）数</span
                    >
                    <span v-else>区县数</span>
                </span>
        <span v-if="information.Hierarchy == 3">街道（司法所）数 </span>
        <span style="margin-left: 30px">共{{ count1 }}条</span>
      </p>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <!--<th v-if="information.adminLevel == 0">省数</th>-->
            <th>名称</th>
            <th v-if="information.adminLevel == 0">市州数</th>
            <th v-if="information.adminLevel == 1 || information.adminLevel == 0">
              <span v-if="information.AdminId == '275'">乡镇数</span>
              <span v-else>区县数</span>
            </th>
            <th v-if="information.Hierarchy < 3">街道（司法所）数</th>
            <th>服务村（社区）</th>
            <th>顾问人数</th>
            <!--              <th>用户人数</th>-->
            <!--              <th>咨询次数</th>-->
            <!--              <th>创建时间</th>-->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in difficultCount">
            <td>{{ value.AdminName }}</td>
            <td v-if="information.adminLevel == 0">{{ value.shiju_count }}</td>
            <td v-if="information.adminLevel == 1 || information.adminLevel == 0">
              {{ value.quxian_count }}
            </td>
            <td v-if="information.Hierarchy < 3">
              {{ value.sfs_count }}
            </td>
            <td style="cursor: pointer" @click="orderTypeCount(1, value.AdminId)">
              {{ value.Villages_count }}
            </td>
            <td>{{ value.Lawyers_count }}</td>
            <!--              <td>{{value.users_count}}</td>-->
            <!--              <td>{{value.Orders_count}}</td>-->
            <!--              <td>{{value.CreatTime}}</td>-->
          </tr>
          </tbody>
        </table>
        <!--<Page-->
        <!--:total="count"-->
        <!--show-total-->
        <!--:page-size="pageSize"-->
        <!--show-elevator-->
        <!--@on-change="page = $event;jurisdiction()"-->
        <!--/>-->
      </div>
    </Modal>
    <!--用户人数-->
    <Modal :draggable="true" v-model="modal11" title="用户管理" @on-ok="" @on-cancel="cancel" width="800">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>用户管理</span>
        <span style="margin-left: 30px">共{{ count }}条</span>
      </p>
      <div class="publicWelfare">
        <DatePicker
            :value="timeData"
            v-model="timeData"
            type="daterange"
            split-panels
            placeholder="请选择时间"
            style="width: 200px"
            @on-change="totalTime3"
        ></DatePicker>

        <Select
            style="width: 200px"
            v-model="model140"
            filterable
            remote
            clearable
            :remote-method="remoteMethod2"
            @on-change="lawyerClick3"
            :loading="loading2"
            placeholder="请输入社区"
        >
          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesId">
            {{ value.VillagesName }}</Option
          >
        </Select>
        <div style="width: 200px; display: inline-block; vertical-align: top">
          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch" />
        </div>
        <table class="formtable">
          <thead>
          <tr>
            <th>姓名</th>
            <th>手机号码</th>
            <th>所在村（社区）</th>
            <th>关注时间</th>
            <th>咨询次数</th>
            <th>申办次数</th>
            <th>查询次数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in adminUserData">
            <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
            <td>{{ value.UserPhone }}</td>
            <td>
              {{ value.village.CityName.replace('重庆市', '') }}{{ value.village._AdminName }}{{ value.village.VillagesName }}
            </td>
            <td>{{ value.CreatTime }}</td>
            <td>{{ value.OrderCount }}</td>
            <td>{{ value.ApplyCount }}</td>
            <td>{{ value.QueryCount }}</td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="count"
            :page-size="pageSize"
            show-elevator
            show-total
            @on-change="
                        page = $event
                        orderTypeCount(2)
                    "
        />
      </div>
    </Modal>
    <!---->
    <Modal
        :draggable="true"
        v-model="modal15"
        title="用户人数"
        :z-index="1"
        @on-ok=""
        @on-cancel="cancel"
        width="1000"
    >
      <p slot="header" style="color: #17233d; text-align: left">
        <span>顾问人数</span>
        <span style="margin-left: 30px">共 {{ count }} 条</span>
        <span style="margin-left: 30px">已绑定顾问 {{ BingCount }} 人</span>
        <span style="margin-left: 30px">顾问平均分 {{ lawyerAvg }} 分</span>
      </p>
      <div class="publicWelfare">
        <Row :gutter="16">
          <Col span="12">
            <Input
                v-model="searchLaw"
                search
                enter-button
                placeholder="搜索律师/律所名称"
                @on-search="searchLawyer"
            />
          </Col>
          <Col span="5">
            <Select v-model="isBinding" placeholder="请选择绑定状态" clearable>
              <Option value="1" label="已绑定">已绑定</Option>
              <Option value="2" label="未绑定">未绑定</Option>
            </Select>
          </Col>
          <!--<Col span="12" style="text-align: center">-->
          <!--<Button type="primary" @click="sendOutinformation()">一键发送</Button>-->
          <!--</Col>-->
        </Row>

        <table class="formtable">
          <thead>
          <tr>
            <th>法律顾问</th>
            <th>所属律所</th>
            <th>电话</th>
            <th>
              共
<!--              <span v-if="avgs == 0">0</span>-->
              <span >{{ sum }}</span>
              次评分
            </th>
            <th>推广人数</th>
            <th>服务数据</th>
            <th v-if="appletCode">
              小程序码
              <!--<Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>-->
            </th>
            <th>是否绑定</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in adminLawyerList">
            <td nowrap="nowrap"><Avatar :src="value.LawyerImg" /> {{ value.LawyerName }}</td>
            <td>{{ value.organization.OrganizationName }}</td>
            <td>{{ value.LawyerPhone }}</td>
            <td v-if="value.avgs == 0">暂无</td>
            <td v-else="" nowrap="nowrap">
                                <span style="cursor: pointer" @click=";(EvaPanel.page = 1), getEva(value.LawyerId)"
                                >{{ Math.floor(value.avgs * 100) / 100 }}分（{{ value.Count }}次评分）</span
                                >
            </td>
            <td>
                                <span
                                    @click="shareUserList(value.LawyerId)"
                                    style="cursor: pointer"
                                    v-if="value.shareUserCount"
                                >{{ value.shareUserCount }}人</span
                                >
              <span @click="shareUserList(value.LawyerId)" style="cursor: pointer" v-else>0人</span>
            </td>
            <td>
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff"
                  @click="
                                        serviceClick(value.LawyerId)
                                        modal19 = true
                                    "
              >详情</Button
              >
            </td>
            <td style="width: 190px" v-if="appletCode">
              <!--<CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange" style="display: inline-block;color:#19be6b;">-->
              <!--<Checkbox :label="value.LawyerPhone+','+value.LawyerId+','+value.OrganizationId" style="">-->
              <!--<div></div>-->
              <!--</Checkbox>-->
              <!--</CheckboxGroup>-->
              <img
                  class="smallProgram"
                  @click="
                                        alertbox1(
                                            value.LawyerQRcode,
                                            value.LawyerName,
                                            value.LawyerId,
                                            value.LawyerPhone,
                                            value.OrganizationId
                                        )
                                    "
                  src="/img/smallProgram1.png"
              />
              <div style="display: inline-block; margin: 0 8%"></div>
              <Button
                  type="success"
                  @click="
                                        alertbox1(
                                            value.LawyerQRcode,
                                            value.LawyerName,
                                            value.LawyerId,
                                            value.LawyerPhone,
                                            value.OrganizationId
                                        )
                                    "
              >发送</Button
              >
            </td>
            <td v-if="value.isBinding == 1" style="color: red">已绑定</td>
            <td v-else>未绑定</td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="count"
            :page-size="pageSize"
            show-elevator
            show-total
            @on-change="
                        page = $event
                        orderTypeCount(3)
                    "
        />
      </div>
    </Modal>
    <!---->
    <Modal :draggable="true" v-model="modal19" title="服务数据" @on-ok="" @on-cancel="" width="650">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>服务数据</span>
      </p>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>服务类型</th>
            <th>服务次数</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>服务村（社区）数</td>
            <td>
              <div style="display: inline-block; margin: 7px auto">
                {{ statisticsData1.villages_count }}
              </div>
              <Button
                  style="background-color: rgb(243, 133, 4); color: #fff; float: right"
                  @click="modal30 = true"
              >详情</Button
              >
            </td>
          </tr>
          <tr>
            <td>服务用户数量</td>
            <td>{{ statisticsData1.user_count }}</td>
          </tr>
          <tr>
            <td>拨打电话数量</td>
            <td>{{ statisticsData1.tel_count }}</td>
          </tr>
          <tr v-for="(value, index) in lawyerOrderTypeData">
            <td>{{ value.OrderTypeTitle }}</td>
            <td>{{ value.count }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <Modal
        :draggable="true"
        v-model="modal30"
        title="服务村（社区）"
        @on-ok=""
        @on-cancel=""
        width="400"
        :z-index="1999"
    >
      <p slot="header" style="color: #17233d; text-align: left">
        <span>服务村（社区）</span>
      </p>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>服务村（社区）</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in lawyerVillagesData">
            <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <!--弹窗img-->
    <!-- <Modal :draggable="true" v-model="modal5" :title="lawName+'的太阳码'" @on-ok="downloadUrl" @on-cancel="" ok-text="下载" -->
    <!-- cancel-text="关闭" width="350"> -->
    <!-- <div style="text-align: center"> -->
    <!--<img v-if="modal5" :src="urlImg" style="width: 300px;height: 300px">-->
    <!-- <div style="width: 300px;height: 300px"> -->
    <!-- <img v-if="modal5 && lawImg" :src="lawImg" style="width: 300px;height: 300px"> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </Modal> -->
    <!--弹窗发送-->
    <Modal
        :draggable="true"
        v-model="modal6"
        :title="lawName + '的太阳码'"
        @on-ok="sendOut"
        @on-cancel=""
        ok-text="发送"
        cancel-text="关闭"
        width="350"
    >
      <div style="text-align: center">
        <p style="font-size: 16px">是否发送律师小程序码至手机</p>
        <!--<img v-if="modal6" :src="urlImg" style="width: 300px;height: 300px">-->
        <div style="width: 300px; height: 300px">
          <img v-if="modal6 && lawImg" :src="lawImg" style="width: 300px; height: 300px" />
        </div>
      </div>
    </Modal>
    <!--    多律师弹窗-->
    <Modal v-model="manyLawyerShow" title="选择律师" @on-ok="manyLawyerok" @on-cancel="cancel" :z-index="9999">
      <RadioGroup v-model="manyLawyerIndex">
        <Radio v-for="(value, index) in manyLawyerData.relation" :label="index">
          <span>{{ value.lawyer.LawyerName }}</span>
        </Radio>
      </RadioGroup>
    </Modal>
    <!--    律师分享用户列表-->

    <Modal :draggable="true" v-model="ShareUserData.state" title="推广人数" @on-ok="" @on-cancel="" width="700">
      <p slot="header" style="color: #17233d; text-align: left">
        <span>推广人数</span>
        <span style="margin-left: 30px">共{{ ShareUserData.count }}条</span>
      </p>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>姓名</th>
            <th>移动电话</th>
            <th>村（社区）</th>
            <th>注册时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in ShareUserData.list">
            <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
            <td>{{ value.UserPhone || '--' }}</td>
            <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
            <td>{{ value.CreatTime }}</td>
          </tr>
          </tbody>
        </table>
        <Page
            :total="ShareUserData.count"
            :page-size="ShareUserData.pageSize"
            show-elevator
            show-total
            @on-change="
                        ShareUserData.page = $event
                        shareUserList(ShareUserData.id)
                    "
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import echarts from 'echarts'
import axios from 'axios'
import QRCode from 'qrcode2'
// import HelloWorld from '../../components/HelloWorld.vue'
import serviceFrequency from '../../components/alert/serviceFrequency'
export default {
  name: 'home',
  components: {serviceFrequency,},
  data() {
    return {
      BingCount: 0,
      EvaPanel: {
        state: false,
        list: [],
        pageSize: 10,
        page: 1,
        count: 0
      },
      ShareUserData: {
        state: false,
        list: [],
        pageSize: 10,
        page: 1,
        count: 0,
        id: ''
      },
      SPC: {
        state: false,
        phoneNum: '',
        lawyerName: '',
        vallageName: '',
        src: ''
      },
      map: {
        mapChart: false,
        mapLodingState: false,
        mapName: '中国',
        mapHistory: [],
        mapData: [
          {
            name: '四川省',
            value: 395,
            type: [
              { name: '服务村（社区）数', value: 152 },
              { name: '律师数量', value: 30 },
              { name: '咨询数量', value: 80 },
              { name: '用户数量', value: 80 }
            ]
          },
          {
            name: '贵州省',
            value: 98,
            type: [
              { name: '服务村（社区）数', value: 12 },
              { name: '律师数量', value: 50 },
              { name: '咨询数量', value: 30 },
              { name: '用户数量', value: 30 }
            ]
          },
          {
            name: '云南省',
            value: 15,
            type: [
              { name: '服务村（社区）数', value: 12 },
              { name: '律师数量', value: 70 },
              { name: '咨询数量', value: 20 },
              { name: '用户数量', value: 20 }
            ]
          }
        ],
        childData: [
          { name: '服务村（社区）', value: 152 },
          { name: '顾问人数', value: 30 },
          { name: '用户人数', value: 30 },
          { name: '咨询次数', value: 80 }
        ]
      },
      yesterdayData: {
        village: '',
        lawyer: '',
        user: '',
        order: ''
      },
      ageDistributedList: [],
      devicesDistributedList: [],
      sexDistributedList: [],
      areaData: null,
      labelInValue: true,
      value2: ['0', '1', '2'],
      addressData: [],
      model8: '',
      model9: '',
      cityList: [],
      ElectronicsList: [],
      statisticsData1: [],
      lawyerOrderTypeData: [],
      lawyerVillagesData: [],
      tipsWord: '请选择地址',
      boxt: 0,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      modal19: false,
      model14: '',
      model140: '',
      loading2: false,
      options2: [],
      VillagesId: '',
      administration: '',
      listData: [],
      arCode: false, //二维码
      current: '',
      lawyerPhone: '',
      lawyerName: '',
      information: [],
      page: 1,
      pageSize: 15,
      count: 0,
      count1: 0,
      opinionpage: 1,
      opinionpageSize: 10,
      typeId: 1,
      adminLogin: '',
      adminPassword: '',
      organizationPhone: '',
      organizationLinkman: '',
      organizationName: '',
      urlImg: '',
      lawImg: '',
      lawName: '',
      lawyerId1: '',
      lLawyerPhone: '',
      AreaId: '',
      sum: '',
      statisticsData: [
        { name: '村（社区）数', value: '', type: '1', img: '0' ,unit:'个',},
        { name: '村居顾问数', value: '', type: '3', img: '1',unit:'人', },
        { name: '累计用户数', value: '', type: '2', img: '2',unit:'人', },
        // { name: '服务用户', value: '', type: '7' , img: '6',},
        { name: '服务次数', value: '', type: '4', img: '3' ,unit:'次',},
        { name: '服务档案数', value: '', type: '6', img: '5' ,unit:'份',},
        { name: '值班打卡', value: '', type: 'kq', img: '4' ,unit:'次',}
      ],
      timelist: [
        { name: '总计', value: 4 },
        { name: '本月', value: 1 },
        { name: '本季', value: 2 },
        { name: '本年', value: 3 }
      ],
      //
      modal10: false,
      adminVillagesData: [],
      //
      modal11: false,
      modal15: false,
      modal16: false,
      modal30: false,
      difficultCount: [],
      countData: '',
      adminLawyerList: [],
      adminUserData: [],
      equipment: [],
      UserOrderLIst: [],
      getOnlineNum: '',
      LawyerName: '',
      searchData: '',

      start_time: '', //开始时间
      end_time: '', //结束时间
      nameKeyId: '',
      lawyerData: [],
      model1: '',
      startTime: '', //开始时间
      endTime: '',
      transfer: true,
      //
      search: '',
      model2: '',
      sort: '',
      serviceType: '',
      serviceName: '',

      clickname: '',

      //
      modelA1: '',
      modelA2: '',
      CommunityData1: [],
      CommunityData2: [],
      CommunityData3: [],
      CommunityData4: [],
      CommunityData5: [],
      modelA3: '',
      modelA4: '',
      modelA5: '',

      //
      getTen_Data: [],
      myChartGetTen: {},
      typeData: '',
      timeData: '',
      effect: '',
      avgs: '',

      searchLaw: '',
      indeterminate: true,
      checkAll: false,
      checkAllGroup: [],
      numberofStatesshow: true,
      monthAssessment: {
        ServiceTime: 0
      },
      fileTotalMonthData :0,
      sysName: '',
      pageUrl: '',
      url: '',
      manyLawyerShow: false,
      manyLawyerData: [],
      manyLawyerIndex: 0,
      userKey: '',
      getEvaLawyerId: '',
      IsReply: '',
      lawyerAvg : '',
      isArchive : '',
      appletCode : true,
      isBinding: '',
    }
  },

  watch: {
    'isBinding': function (val) {this.orderTypeCount(3)},
  },
  mounted() {
    var _this = this
    _this.map.mapName = window.sessionStorage.AreaName
    _this.url = window.sessionStorage.url
    // _this.getMapGeo()
    // _this.sexDistributed()
    // _this.ageDistributed()
    // _this.allDataView()
    // _this.devicesDistributed()
    _this.newlyDataView()
    _this.getYesterdayData()
    _this.onLine()
    if (window.sessionStorage.adminLevel == undefined) {
      // this.$router.push({name:'login'})
    } else {
      // var data = JSON.parse(window.sessionStorage.information)
      // SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)
      // console.log(SYSCONFIG.server.information.AdminLogin)
      this.information = window.sessionStorage
      if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){_this.appletCode = false}
      if (_this.information.adminLevel == 4) {
        _this.lawList()
        console.log(this.information)
      }
      if (_this.information.adminLevel == 3) {
        _this.adminList()
        console.log(this.information)
      }
      _this.getIndexCount()
      _this.switchData(0)
      _this.getAreaData()
      _this.getTenData()
      _this.getMonthAssessment()
      // _this.jurisdiction()
    }
    _this.sysName = window.sessionStorage.sysName
    if (SYSCONFIG.location == 'chong-qing') {
      _this.pageUrl = 'chong-qing/login/login'
    } else {
      _this.pageUrl = 'pages/login/login'
    }
  },
  methods: {
    handleMessageUpdated(message) {
      this.modal3 = message;
    },
    userSearch: function (e) {
      this.userKey = e
      this.orderTypeCount(2)
    },
    getMonthAssessment: function () {
      var _this = this
      _this.send({
        url: '/MouthClock',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: (res) => {
          _this.monthAssessment = res
        }
      })
      _this.send({
        url: '/fileTotalMonth',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: (res) => {
          _this.fileTotalMonthData = res
        }
      })
    },
    numberofStates: function () {
      var _this = this
      _this.modal16 = true
      _this.jurisdiction()
    },
    getIndexCount: function () {
      var _this = this
      _this.send({
        url: '/GetIndexCount',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          _this.count1 = res.count
          _this.countData = res.count
          console.log(res)
        }
      })
    },
    shareUserList: function (id) {
      var _this = this
      _this.send({
        url: '/ShareUserList',
        data: {
          LawyerId: id,
          page: _this.ShareUserData.page,
          pageSize: _this.ShareUserData.pageSize
        },
        success: function (res) {
          _this.ShareUserData.state = true
          _this.ShareUserData.id = id
          _this.ShareUserData.list = res.List
          _this.ShareUserData.count = res.Count
        }
      })
    },
    getEva: function (id) {
      var _this = this
      _this.EvaPanel.list = []
      _this.getEvaLawyerId = id
      _this.send({
        url: '/OrderGradeList',
        data: {
          LawyerId: id,
          page: _this.EvaPanel.page,
          pageSize: _this.EvaPanel.pageSize
        },
        success: function (res) {
          _this.EvaPanel.state = true
          _this.EvaPanel.list = res.data
          _this.EvaPanel.page = res.SumPage
          _this.EvaPanel.count = res.Count
        }
      })
    },
    //搜索
    searchLawyer: function () {
      console.log(this.searchLaw)
      this.page = 1
      this.orderTypeCount(3)
    },
    //发送
    sendOutinformation: function () {
      var _this = this
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/SendMessage'
      } else {
        var url = '/CD_SendMessage'
      }
      for (var i in _this.checkAllGroup) {
        console.log(_this.checkAllGroup[i].split(','))
        _this.send({
          url: url,
          data: {
            LawyerId: _this.checkAllGroup[i].split(',')[1],
            LawyerPhone: _this.checkAllGroup[i].split(',')[0]
          },
          success: function (res) {
            if (res.Code == 'OK') {
            }
          }
        })
      }
      _this.$Notice.warning({
        title: '系统提示',
        desc: '发送成功'
      })
    },
    //全选
    checkAllGroupChange: function (data) {
      var _this = this
      _this.checkAllGroup = data
      var Data = data

      // for(var i in Data) {
      //     console.log(Data[i].split(','))
      //     _this.send({
      //         url : '/getLawyerSunCode',
      //         data: {
      //             OrganizationId : Data[i].split(',')[2],
      //             scene: Data[i].split(',')[2]+'p'+Data[i].split(',')[0] +'p'+1+'p'+Data[i].split(',')[1],
      //             userType : '2',
      //             page: 'pages/login/login',
      //             width: '430',
      //             jpg: 'jpg',
      //             LawyerId : Data[i].split(',')[1]
      //         },
      //         success: function (res) {
      //             _this.lawImg = 'https://vn-server.lvwa18.com/'+res
      //         }
      //     })
      // }
    },
    handleCheckAll: function () {
      var _this = this
      var checkAllGroup = [] //全选
      var back = [] //反选
      console.log(_this.checkAllGroup.length, _this.adminLawyerList.length)
      console.log(_this.checkAllGroup.length, _this.adminLawyerList.length)
      // value.LawyerPhone+','+value.LawyerId
      if (_this.checkAllGroup.length == 0) {
        //全选
        for (var i in _this.adminLawyerList) {
          checkAllGroup.push(
              _this.adminLawyerList[i].LawyerPhone +
              ',' +
              _this.adminLawyerList[i].LawyerId +
              ',' +
              _this.adminLawyerList[i].OrganizationId
          )
        }
        _this.checkAllGroup = checkAllGroup
        console.log(1)
      }
      // _this.checkAllGroup.length < _this.adminLawyerList.length && _this.checkAllGroup.length != 0
      else {
        //反选
        console.log(_this.checkAllGroup)
        for (var i in _this.adminLawyerList) {
          checkAllGroup.push(
              _this.adminLawyerList[i].LawyerPhone +
              ',' +
              _this.adminLawyerList[i].LawyerId +
              ',' +
              _this.adminLawyerList[i].OrganizationId
          )
        }
        // 首先构造Object
        const idSet = _this.checkAllGroup.reduce((acc, v) => {
          acc[v] = true
          return acc
        }, {})
        // 遍历list2，去掉在idSet中存在的id
        const result = checkAllGroup.filter((v) => !idSet[v])
        _this.checkAllGroup = result
        console.log(2)
      }
      var Data = _this.checkAllGroup
      // for(var i in Data) {
      //     console.log(Data[i].split(','))
      //     _this.send({
      //         url : '/getLawyerSunCode',
      //         data: {
      //             OrganizationId : Data[i].split(',')[2],
      //             scene: Data[i].split(',')[2]+'p'+Data[i].split(',')[0] +'p'+1+'p'+Data[i].split(',')[1],
      //             userType : '2',
      //             page: 'pages/login/login',
      //             width: '430',
      //             jpg: 'jpg',
      //             LawyerId : Data[i].split(',')[1]
      //         },
      //         success: function (res) {
      //
      //         }
      //     })
      // }
    },
    manyLawyerok: function () {
      var _this = this
      console.log(_this.manyLawyerData.adminname)
      var index = _this.manyLawyerIndex
      console.log(_this.manyLawyerData.LawyerName, index)
      _this.SPC = {
        state: false,
        phoneNum: '',
        lawyerName: '',
        vallageName: '',
        src: ''
      }
      _this.SPC.phoneNum = _this.manyLawyerData.relation[index].lawyer.LawyerPhone
      // _this.SPC.areaName = item.CityName+(item.AreaName==item.CityName?'':item.AreaName)
      _this.SPC.areaName = _this.manyLawyerData.adminname
      _this.SPC.VNName = _this.manyLawyerData.VillagesName
      _this.SPC.lawyerName = _this.manyLawyerData.relation[index].lawyer.LawyerName
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/getVillageSunCode'
      } else {
        // 判断当前账号（海南）
        if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
          var url = 'https://onecode-h5.digitalhainan.com.cn/online/qlink?linkCode=mZcfTkVp&type=qrCode_2&VillagesId='+_this.manyLawyerData.VillagesId
          _this.qRCode(url)
          return
        }else {
          var url = '/CD_getVillageSunCode'
        }
      }
      _this.send({
        url: url,
        consulting1:true,
        responseType:"arraybuffer",
        data: {
          scene: _this.manyLawyerData.relation[index].LawyerId + 'p' + _this.manyLawyerData.relation[index].VillagesId + 'p' + 2,
          LawyerId: _this.manyLawyerData.relation[index].LawyerId,
          width: '430',
          jpg: 'jpg',
          page: _this.pageUrl,
          userType: '1',
          VillagesId: _this.manyLawyerData.relation[index].VillagesId
        },
        success: function (res) {
          _this.SPC.state = true

          ;(_this.SPC.src = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)))), (_this.manyLawyerIndex = 0)
          _this.card()
        }
      })
    },
    qRCode : function (url){
      this.SPC.state = true
      console.log(url)
      if(this.SPC.state){
        document.getElementById('qrcode').innerHTML = ''
        let tmp = document.getElementById('qrcode');
        var qrcode = new QRCode('qrcode', { // 第一个入参是组件id
          text: url, // 生成二维码的文本
          width: '360',
          height: '360',
          colorDark: '#0067ff', // 二维码色
          colorLight: '#ffffff', // 背景色
          correctLevel: QRCode.CorrectLevel.H // 容错等级，H是heigh，最高，所以二维码看起来很密
        })
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = "/img/HYB.png"
        // logo.fillText = ('海南村居法律服务平台')
        let that = this;
        console.log(this.$refs.qrcode)
        let con = this.$refs.qrcode;
        logo.onload = function() {
          let container = con;
          let qrImg = qrcode._el.getElementsByTagName('img')[0];
          let canvas = qrcode._el.getElementsByTagName('canvas')[0];
          var ctx = canvas.getContext("2d");
          ctx.drawImage(logo, 360 * 0.5 - 44, 360 * 0.5 - 44, 88, 88);
          qrImg.src = canvas.toDataURL();
          container.appendChild(qrcode._el);
          // 销毁临时dom
          tmp = null;
          qrcode = null;
          logo = null;
        }
        setTimeout(() => {this.card()}, 500);
        console.log(document.getElementById('qrcode').getElementsByTagName("img")[0].src)
        console.log(qrcode)
      }
    },
    getSPC: function (item) {
      var _this = this
      console.log(item)
      if (item.relation.length > 1) {
        _this.manyLawyerShow = true
        _this.manyLawyerData = item
      } else {
        _this.SPC = {
          state: false,
          phoneNum: '',
          lawyerName: '',
          vallageName: '',
          src: ''
        }
        _this.SPC.phoneNum = item.relation[0].lawyer.LawyerPhone
        // _this.SPC.areaName = item.CityName+(item.AreaName==item.CityName?'':item.AreaName)
        _this.SPC.areaName = item.adminname
        _this.SPC.VNName = item.VillagesName
        _this.SPC.lawyerName = item.relation[0].lawyer.LawyerName
        if (SYSCONFIG.location == 'chong-qing') {
          var url = '/getVillageSunCode'
        } else {
          // 判断当前账号（海南）
          if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
            var url = 'https://onecode-h5.digitalhainan.com.cn/online/qlink?linkCode=mZcfTkVp&type=qrCode_2&VillagesId='+item.VillagesId
            _this.qRCode(url)
              return
          }else {
            var url = '/CD_getVillageSunCode'
          }
        }
        //简阳-云律所
        if (item.VillagesId == '22621') {
          var scene = item.relation[0].LawyerId + 'p' + item.relation[0].VillagesId + 'p' + 3
        } else {
          var scene = item.relation[0].LawyerId + 'p' + item.relation[0].VillagesId + 'p' + 2
        }
        _this.send({
          url: url,
          consulting1:true,
          responseType:"arraybuffer",
          data: {
            scene: scene,
            LawyerId: item.relation[0].LawyerId,
            width: '430',
            jpg: 'jpg',
            page: _this.pageUrl,
            userType: '1',
            VillagesId: item.relation[0].VillagesId
          },
          success: function (res) {
            _this.SPC.state = true
            _this.SPC.src = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
            _this.card()
          }
        })
      }
    },
    downloadCard: function () {
      var _this = this
      var canvas = document.getElementById('springCanvas') //获取canvas
      var base64 = canvas.toDataURL('image/png')
      console.log(base64)
      const imgUrl = base64
      if (window.navigator.msSaveOrOpenBlob) {
        let bstr = atob(imgUrl.split(',')[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        let blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(
            blob,
            _this.SPC.VNName + '-' + _this.SPC.lawyerName + '-便民卡' + '.' + 'png'
        )
      } else {
        // 这里就按照chrome等新版浏览器来处理
        let a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', _this.SPC.VNName + '-' + _this.SPC.lawyerName + '-便民卡')
        a.click()
      }
    },
    card: function () {
      var _this = this
      if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
        var qRCode = document.getElementById('qrcode').getElementsByTagName("img")[0].src
      }else {var qRCode = _this.SPC.src}
      //  便民卡
      var canvas = document.getElementById('springCanvas')
      var ctx = canvas.getContext('2d')
      var img = new Image()
      img.src = 'https://ai-cdn.lvwa18.com/img/rusticate/card1.jpg'
      img.setAttribute('crossOrigin', 'Anonymous')
      //浏览器加载图片完毕后再绘制图片
      img.onload = function () {
        //以Canvas画布上的坐标(10,10)为起始点，绘制图像
        ctx.drawImage(img, 0, 0)
        ctx.font = 'normal bold 55px Arial, sans-serif'
        ctx.fillStyle = 'rgba(69, 69, 69, 1)' //填充颜色
        ctx.fillText(_this.SPC.VNName + '法律服务', 215, 133)
        ctx.font = 'normal bold 22px Arial, sans-serif'
        ctx.fillStyle = 'rgba(69, 69, 69, 1)' //填充颜色
        ctx.fillText(_this.SPC.lawyerName + '：' + _this.SPC.phoneNum, 750, 530)
        ctx.font = 'normal bold 24px Arial, sans-serif'
        ctx.fillStyle = 'rgba(23, 156, 209, 1)' //填充颜色
        ctx.fillText(_this.SPC.areaName, 600, 590)

        var img1 = new Image()
        img1.src = qRCode
        img1.setAttribute('crossOrigin', 'Anonymous')
        img1.onload = function () {
          ctx.drawImage(img1, 685, 154, 311, 311)
        }
      }

      // ctx.stroke();
    },
    onLine: function () {
      var _this = this
      axios({
        url: 'https://node.lvwa18.com/socket/getOnlineNum',
        method: 'get',
        params: { AdminId: window.sessionStorage.AdminId, client: 'lvwa18' }
      }).then((res) => {
        _this.getOnlineNum = res.data.data.onlineNum
        console.log('请求结果：', res.data)
      })
    },
    getYesterdayData: function () {
      var _this = this
      _this.send({
        url: '/YesterdayGetCount',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          _this.yesterdayData = {
            village: res.villages_sql,
            lawyer: res.lawyer_count,
            user: res.user_count,
            order: res.order_count
          }
        }
      })
    },
    allDataView: function () {
      var _this = this
      let allDataDom = echarts.init(document.getElementById('allDataView'))
      _this.send({
        url: '/GetCount',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          var time = [] //时间
          var order_count = [] //咨询数量
          var lawyer_count = [] //律师数量
          var user_count = [] //用户数量
          var villages_sql = [] //创建村数量
          for (var i in res) {
            // for (var i = 19;i< res.list.length;i++) {
            time.push(res[i].time)
            order_count.push(res[i].order_count)
            lawyer_count.push(res[i].lawyer_count)
            user_count.push(res[i].user_count)
            villages_sql.push(res[i].villages_sql)
          }
          // 绘制图表
          allDataDom.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            xAxis: {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: time
            },
            legend: {
              data: ['服务村（社区）', '用户人数', '顾问人数', '咨询次数'],
              left: 'center'
              // top: 50
            },
            grid: {
              // top: 100
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '服务村（社区）',
                type: 'line',
                data: villages_sql
              },
              {
                name: '用户人数',
                type: 'line',
                data: user_count
              },
              {
                name: '顾问人数',
                type: 'line',
                data: lawyer_count
              },
              {
                name: '咨询次数',
                type: 'line',
                data: order_count
              }
            ]
          })
        }
      })
    },
    devicesDistributed: function () {
      var _this = this
      _this.send({
        url: '/UserPhoneTypeCount',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          var title = []
          var data = []
          _this.equipment = []
          for (var index in res) {
            title.push(res[index].title)
            data.push({ name: res[index].title, value: res[index].total })
            if (res[index].PhoneType == null) {
              _this.equipment.push({
                // key:res[index].PhoneType,
                name: '未知',
                value: res[index].user_count
              })
            }
            if (res[index].PhoneType == '1') {
              _this.equipment.push({
                name: 'Android',
                value: res[index].user_count
              })
            }
            if (res[index].PhoneType == '2') {
              _this.equipment.push({
                name: 'Iphone',
                value: res[index].user_count
              })
            }
            if (res[index].PhoneType == '3') {
              _this.equipment.push({
                name: '未知',
                value: res[index].user_count
              })
            }
          }
          var devicesDom = echarts.init(document.getElementById('devicesDistributed'))
          var option = {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: { top: 40 },
            series: [
              {
                name: '访问设备',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '24',
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: _this.equipment
              }
            ]
          }
          devicesDom.setOption(option, true)
        }
      })
    },
    // ageDistributed:function(){
    //     var _this = this
    //     _this.send({
    //         url:'/UserAgeCount',
    //         data:{
    //             AdminId:window.sessionStorage.AdminId
    //         },
    //         success:function (res) {
    //             var title = []
    //             var data = []
    //             _this.ageDistributedList = []
    //             for(var index in res){
    //                 title.push(res[index].title)
    //                 data.push({name:res[index].title,value:res[index].total})
    //                 _this.ageDistributedList.push({key:res[index].title,value:res[index].total})
    //             }
    //             var ageDom = echarts.init(document.getElementById('ageDistributed'))
    //             var option = {
    //                 tooltip: {
    //                     trigger: 'item',
    //                     formatter: '{a} <br/>{b}: {c} ({d}%)'
    //                 },
    //                 legend: {},
    //                 series: [
    //                     {
    //                         name: '年龄分布',
    //                         type: 'pie',
    //                         radius: ['50%', '70%'],
    //                         avoidLabelOverlap: false,
    //                         label: {
    //                             show: false,
    //                             position: 'center'
    //                         },
    //                         emphasis: {
    //                             label: {
    //                                 show: true,
    //                                 fontSize: '24',
    //                                 fontWeight: 'bold'
    //                             }
    //                         },
    //                         labelLine: {
    //                             show: false
    //                         },
    //                         data: data
    //                     }
    //                 ]
    //             }
    //             ageDom.setOption(option, true);
    //         }
    //     })

    // },
    // sexDistributed:function(){
    //     var _this = this
    //     var sexDom = echarts.init(document.getElementById('sexDistributed'))
    //     _this.send({
    //         url:'/UserSexCount',
    //         data:{
    //             AdminId:window.sessionStorage.AdminId,
    //         },
    //         success:function (res) {
    //             var sexObj = {}
    //             _this.sexDistributedList = []
    //             for(var index in res){
    //                 if(res[index].UserSex==2){
    //                     sexObj.men = res[index].user_count
    //                     _this.sexDistributedList.push({key:'男',value:res[index].user_count})
    //                 }else if(res[index].UserSex == 1){
    //                     sexObj.women = res[index].user_count
    //                     _this.sexDistributedList.push({key:'女',value:res[index].user_count})
    //                 }else{
    //                     sexObj.others = res[index].user_count
    //                     _this.sexDistributedList.push({key:'未知',value:res[index].user_count})
    //                 }
    //             }
    //             var option = {
    //                 tooltip: {
    //                     trigger: 'item',
    //                     formatter: '{a} <br/>{b}: {c} ({d}%)'
    //                 },
    //                 legend: {top:40},
    //                 series: [
    //                     {
    //                         name: '性别分布',
    //                         type: 'pie',
    //                         radius: ['50%', '70%'],
    //                         avoidLabelOverlap: false,
    //                         label: {
    //                             show: false,
    //                             position: 'center'
    //                         },
    //                         emphasis: {
    //                             label: {
    //                                 show: true,
    //                                 fontSize: '24',
    //                                 fontWeight: 'bold'
    //                             }
    //                         },
    //                         labelLine: {
    //                             show: false
    //                         },
    //                         data: [
    //                             {value: sexObj.men, name: '男'},
    //                             {value: sexObj.women, name: '女'},
    //                             {value: sexObj.others, name: '未知'},
    //                         ]
    //                     }
    //                 ]
    //             };
    //             sexDom.setOption(option, true);
    //         }
    //     })
    // },
    backMap: function () {
      this.map.mapName = this.map.mapHistory[this.map.mapHistory.length - 2]
      this.map.mapHistory.length = this.map.mapHistory.length - 1
      this.getMapGeo()
    },
    getMapGeo: function () {
      var _this = this
      _this.map.mapLodingState = true
      if (!_this.map.mapChart) {
        _this.map.mapChart = echarts.init(document.getElementById('mapChart'))
      }
      _this.map.mapChart.showLoading({
        text: '数据正在加载...',
        textStyle: { fontSize: 30, color: '#444' },
        effectOption: { backgroundColor: 'rgba(0, 0, 0, 0)' }
      })
      axios
          .get('https://restapi.amap.com/v3/config/district?parameters', {
            params: {
              key: '82c553750fcb97e01ef7b6e6e581ae79',
              keywords: _this.map.mapName,
              districts: 0
            }
          })
          .then((res) => {
            if (!res.data.districts[0]) {
              _this.map.mapChart.hideLoading()
              _this.map.mapLodingState = false
              _this.$Message.warning({
                content: '已经是最详细区域！'
              })
            } else {
              axios.get('/geo-json/' + res.data.districts[0].adcode + '.json').then((res1) => {
                if (res.data.districts[0].adcode == '500000') {
                  _this.map.mapData = []
                  for (var i in res.data.districts[0].districts) {
                    _this.getMapData(res.data.districts[0].districts[i].adcode).then((mapData) => {
                      _this.map.mapName = _this.map.mapName
                      if (
                          _this.map.mapHistory[_this.map.mapHistory.length - 1] != _this.map.mapName
                      ) {
                        _this.map.mapHistory.push(_this.map.mapName)
                      }
                      _this.loadMap(res1.data)
                    })
                  }
                } else {
                  _this.map.mapData = []
                  _this.getMapData(res.data.districts[0].adcode).then((mapData) => {
                    _this.map.mapName = _this.map.mapName
                    if (_this.map.mapHistory[_this.map.mapHistory.length - 1] != _this.map.mapName) {
                      _this.map.mapHistory.push(_this.map.mapName)
                    }
                    _this.loadMap(res1.data)
                  })
                }
              })
            }
          })
    },
    loadMap: function (mapGeo) {
      var _this = this
      var myChart = _this.map.mapChart
      echarts.registerMap(_this.map.mapName, mapGeo)
      var mapOption = {
        title: {
          text: _this.sysName + ' 地图分布状况 - ' + _this.map.mapName,
          subtext: '鼠标点击可查看下级目录',
          left: 'center'
        },
        visualMap: {
          inRange: {
            color: ['lightskyblue', 'yellow', 'orangered']
          }
        },
        series: [
          {
            type: 'map',
            map: _this.map.mapName,
            name: '服务村（社区）数',
            data: _this.map.mapData
          }
        ]
      }
      myChart.setOption(mapOption, true)
      myChart.on('mousemove', function (params) {
        if (!params.data.type) {
          return
        }
        _this.map.childData = params.data.type || {}
        _this.$refs.childPanel.style.display = 'block'
        _this.$refs.childPanel.style.left = params.event.offsetX + 20 + 'px'
        _this.$refs.childPanel.style.top = params.event.offsetY + 20 + 'px'
      })
      myChart.on('mouseout', function (params) {
        _this.$refs.childPanel.style.display = 'none'
      })

      if (myChart._$handlers.click) {
        myChart._$handlers.click.length = 0
      }
      myChart.on('click', function (params) {
        _this.map.mapName = params.name
        _this.getMapGeo()
      })
      myChart.hideLoading()
      _this.map.mapLodingState = false
    },
    getMapData: function (adcode) {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.send({
          url: '/_GetMapCount',
          data: {
            AreaCode: adcode
          },
          success: function (res) {
            // _this.map.mapData = []
            for (var index in res) {
              _this.map.mapData.push({
                name: res[index].AreaName,
                value:
                    res[index].villages_count +
                    res[index].lawyer_count +
                    res[index].order_count +
                    res[index].user_count,
                type: [
                  { name: '服务村（社区）数', value: res[index].villages_count },
                  { name: '顾问人数', value: res[index].lawyer_count },
                  { name: '用户人数', value: res[index].user_count },
                  { name: '咨询次数', value: res[index].order_count }
                ]
              })
            }
            resolve(res)
          }
        })
      })
    },
    newlyDataView: function () {
      var _this = this
      // 新增
      let myChart1 = echarts.init(document.getElementById('myChart1'), 'light')
      _this.send({
        url: '/GetManageData',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          var time = [] //时间
          var order_count = [] //咨询数量
          var clock_count = [] //服务次数
          var user_count = [] //用户数量
          var file_count = [] //档案次数
          for (var i in res.list.day) {
            time.push(res.list.day[i].time)
            order_count.push(res.list.day[i].order_count)
            clock_count.push(res.list.day[i].clock_count)
            user_count.push(res.list.day[i].user_count)
            file_count.push(res.list.day[i].file_count)
          }
          myChart1.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            // title: { text: "十日新增数据", left: "center" },
            xAxis: {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: time
            },
            legend: {
              data: ['用户人数', '服务次数', '服务档案数', '值班打卡'],
              left: 'center'
              // top: 50
            },
            grid: {
              // top: 100
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [

              {
                name: '用户人数',
                type: 'bar',
                data: user_count,
                itemStyle:{
                  color:"#E6C240",barBorderRadius:4,
                }
              },
              {
                name: '服务次数',
                type: 'bar',//line
                data: order_count,
                itemStyle:{
                  color:"#5BD092",barBorderRadius:4,
                }
              },
              {
                name: '服务档案数',
                type: 'bar',
                data: file_count,
                itemStyle:{
                  color:"#617BE9",barBorderRadius:4,
                }
              },
              {
                name: '值班打卡',
                type: 'bar',
                data: clock_count,
                itemStyle:{
                  color:"#39BAE3",barBorderRadius:4,
                }
              }
            ]
          })
        }
      })
    },
    deleteLawyer: function (lawyerId) {
      var _this = this
      _this.send({
        url: '/DeleteLawyer',
        confirm: true,
        data: {
          OrganizationId: _this.information.OrganizationId,
          LawyerId: lawyerId
        },
        success: function (res) {
          console.log(res)
          _this.listData = []
          _this.page = '1'
          _this.lawList()
        }
      })
    },
    lawList: function () {
      var _this = this
      _this.send({
        url: '/LawyerListById',
        data: {
          OrganizationId: _this.information.OrganizationId,
          page: _this.page,
          pageSize: _this.pageSize
        },
        success: function (res) {
          _this.listData = res.List
          _this.count = res.count
        }
      })
    },
    addMember: function () {
      var _this = this
      console.log(_this.AreaId)
      if (
          _this.organizationName != '' &&
          _this.organizationPhone != '' &&
          _this.organizationLinkman != '' &&
          _this.adminPassword != '' &&
          _this.adminLogin != ''
      ) {
        if (_this.cityList.length == 0) {
          _this.AreaId = _this.information.AreaId
        }
        _this.send({
          url: '/AddMember',
          data: {
            Level: _this.information.adminLevel,
            AdminId: _this.information.AdminId,
            AdminLogin: _this.adminLogin,
            AdminPassword: _this.adminPassword,
            OrganizationLinkman: _this.organizationLinkman,
            OrganizationPhone: _this.organizationPhone,
            OrganizationName: _this.organizationName,
            AreaId: _this.AreaId
          },
          success: function (res) {
            _this.adminList()

            _this.adminLogin = ''
            _this.adminPassword = ''
            _this.organizationLinkman = ''
            _this.organizationPhone = ''
            _this.organizationName = ''
            _this.AreaId = ''
            _this.model8 = ''
          }
        })
      } else {
        _this.$Notice.warning({
          title: '系统提示',
          desc: '输入完成后添加'
        })
      }
    },
    ok: function () {
      var _this = this
      var op = _this.information.OrganizationId + 'p' + _this.lawyerPhone + 'p' + 1
      if (_this.lawyerName != '' && _this.lawyerPhone != '') {
        _this.send({
          url: '/AddLawyerInfo',
          data: {
            OrganizationId: _this.information.OrganizationId,
            scene: op,
            userType: '2',
            page: _this.pageUrl,
            width: '430',
            jpg: 'jpg',
            LawyerName: _this.lawyerName,
            LawyerPhone: _this.lawyerPhone
          },
          success: function (res) {
            _this.listData = []
            _this.page = '1'
            _this.lawList()
          }
        })
      } else {
        _this.$Notice.warning({
          title: '系统提示',
          desc: '名称或电话不能为空'
        })
      }
    },
    cancel() {
      var _this = this
      _this.page = 1
      _this.count = 0
      _this.UserOrderLIst = []
      _this.model1 = ''
      _this.model14 = ''
      // this.endTime = ''
      // this.startTime = ''
      // _this.timeData = ''
      console.log(_this.endTime, _this.startTime)
      // _this.listData = []
    },
    enter: function (index) {
      console.log(index)
      this.arCode = true
      this.current = index
    },
    alertbox: function (url, name, lawyerPhone, LawyerId, OrganizationId) {
      var _this = this
      var op = OrganizationId + 'p' + lawyerPhone + 'p' + 1 + 'p' + LawyerId
      if(SYSCONFIG.location == 'chong-qing'){
        var url1 = '/getLawyerSunCode'
      }else {
        var url1 = '/CD_getLawyerSunCode'
      }
      _this.send({
        url: url1,
        consulting1:true,
        responseType:"arraybuffer",
        data: {
          OrganizationId: OrganizationId,
          scene: OrganizationId + 'p' + lawyerPhone + 'p' + 1 + 'p' + LawyerId,
          userType: '2',
          page: _this.pageUrl,
          width: '430',
          jpg: 'jpg',
          LawyerId: LawyerId
        },
        success: function (res) {
          _this.lawImg = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
        }
      })
      console.log(op)
      _this.modal6 = true
      // _this.urlImg = url
      _this.lawName = name
    },
    alertbox1: function (url, name, lawyerId1, lLawyerPhone, OrganizationId) {
      var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        var url = '/getLawyerSunCode'
      }else {
        var url = '/CD_getLawyerSunCode'
      }
      var op = OrganizationId + 'p' + lLawyerPhone + 'p' + 1 + 'p' + lawyerId1 + ''
      _this.send({
        url: url,
        consulting1:true,
        responseType:"arraybuffer",
        data: {
          OrganizationId: OrganizationId,
          scene: op,
          userType: '2',
          page: _this.pageUrl,
          width: '430',
          jpg: 'jpg',
          LawyerId: lawyerId1
        },
        success: function (res) {
          _this.lawImg = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
        }
      })
      console.log(op)
      _this.modal6 = true
      // _this.urlImg = url
      _this.lawName = name
      _this.lawyerId1 = lawyerId1
      _this.lLawyerPhone = lLawyerPhone
    },
    // downloadUrl: function () {
    //   var _this = this
    //   _this.modal5 = false
    //   // window.URL = _this.urlImg
    //   var downloadIamge = function (imgsrc, name) { //下载图片地址和图片名
    //     let image = new Image();
    //     // 解决跨域 Canvas 污染问题
    //     image.setAttribute("crossOrigin", "anonymous");
    //     image.onload = function () {
    //       let canvas = document.createElement("canvas");
    //       canvas.width = image.width;
    //       canvas.height = image.height;
    //       let context = canvas.getContext("2d");
    //       context.drawImage(image, 0, 0, image.width, image.height);
    //       let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    //       let a = document.createElement("a"); // 生成一个a元素
    //       let event = new MouseEvent("click"); // 创建一个单击事件
    //       a.download = name || "photo"; // 设置图片名称
    //       a.href = url; // 将生成的URL设置为a.href属性
    //       a.dispatchEvent(event); // 触发a的单击事件
    //     };
    //     image.src = imgsrc;
    //   }
    //   downloadIamge(_this.urlImg, _this.lawName + ".jpg");
    // },
    leave() {
      this.arCode = false
      this.current = null
    },
    //    统计
    switchData: function (e) {
      var _this = this
      _this.typeId = _this.timelist[e].value
      _this.send({
        url: '/OrganizationCountVillage',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.statisticsData[0].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountLawyer',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.statisticsData[1].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountUser',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          // switch(parseInt(window.sessionStorage.AdminId)){
          //           case 1630: res.count += 55421+300;break;
          //           case 3172: res.count += 32521+300;break;
          //           case 3050: res.count += 6910+300;break;
          //           case 3358: res.count += 16189+300;break;
          //           case 2346: res.count += 32322+300;break;
          //           case 3323: res.count += 13286+300;break;
          //           case 2104: res.count += 1326+300;break;
          //           case 2899: res.count += 16865+300;break;
          //           case 3096: res.count += 14997+300;break;
          //           case 1870: res.count += 6183+300;break;
          //           case 2736: res.count += 13415+200;break;
          //           case 3138: res.count += 5991+200;break;
          //           case 2298: res.count += 18352+200;break;
          //           case 2646: res.count += 469+200;break;
          //           case 1629: res.count += 894+200;break;
          //           case 2479: res.count += 2797+200;break;
          //           case 2017: res.count += 10514+200;break;
          //           case 1631: res.count += 700+200;break;
          //           case 2977: res.count += 3472+200;break;
          //           case 2574: res.count += 6760+200;break;
          //           case 2392: res.count += 1023+100;break;
          //           case 2450: res.count += 1501+100;break;
          //           case 1978: res.count += 370+100;break;
          //           case 1947: res.count += 240+100;break;
          //           case 3219: res.count += 1766+100;break;
          //           case 3016: res.count += 3462+100;break;
          //           case 1912: res.count += 2626+100;break;
          //           case 2867: res.count += 136+100;break;
          //           case 2773: res.count += 3743+100;break;
          //           case 2805: res.count += 2359+100;break;
          //           case 2531: res.count += 829+50;break;
          //           case 2603: res.count += 863+50;break;
          //           case 1787: res.count += 21+50;break;
          //           case 3339: res.count += 543+50;break;
          //           case 2253: res.count += 129+50;break;
          //           case 2696: res.count += 114+50;break;
          //           case 2150: res.count += 225+50;break;
          //           case 2938: res.count += 758+50;break;
          //           case 2030: res.count += 553+50;break;
          //           case 3249: res.count += 148+50;break;
          //           case 2848: res.count += 194+50;break;
          //           case 2848: res.count += 194+50;break;
          //           case 166: res.count += +289738;break;
          //         }
          _this.statisticsData[2].value = res.count
        }
      })
      // _this.send({
      //   url: '/pl_UserOrder',
      //   data: {
      //     AdminId: window.sessionStorage.AdminId,
      //     start_time :_this.startTime,
      //     end_time :_this.endTime,
      //   },
      //   success: function (res) {
      //     _this.statisticsData[3].value = res.UserCount
      //   }
      // })
      // _this.send({
      //   url: '/AdminVisitCount',
      //   data: {
      //     AdminId: window.sessionStorage.AdminId,
      //     start_time :_this.startTime,
      //     end_time :_this.endTime,
      //   },
      //   success: function (res) {
      //     _this.statisticsData[3].value = res.count
      //   }
      // })
      _this.send({
        url: '/OrganizationCountClock',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.statisticsData[5].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountFile',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.statisticsData[4].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountOrder',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          _this.statisticsData[3].value = res.count
        }
      })
      // _this.send({
      //   url: '/GetOrganizationCount',
      //   data: {
      //     AdminId: window.sessionStorage.AdminId,
      //     typeId: _this.typeId,
      //     start_time: _this.startTime,//开始时间
      //     end_time: _this.endTime
      //   },
      //   success: function (res) {
      //     _this.statisticsData[0].value = res.villages_count
      //     _this.statisticsData[1].value = res.lawyer_count
      //     _this.statisticsData[2].value = res.user_count
      //     _this.statisticsData[3].value = res.order_count
      //     _this.statisticsData[4].value = res.clock_count
      //     _this.statisticsData[5].value = res.file_count
      //   }
      // })
    },
    //    管理列表
    adminList: function () {
      var _this = this
      _this.send({
        url: '/AdminList',
        data: {
          AdminId: _this.information.AdminId
        },
        success: function (res) {
          console.log(res)
          _this.listData = res
          _this.count = res.count
        }
      })
    },
    //    服务村
    orderTypeCount: function (type, adminId, index) {
      var _this = this
      console.log(type)
      if (type == 1) {
        _this.modal10 = true
        _this.adminVillagesData = []
        console.log(adminId)
        if (adminId == undefined) {
          _this.administration = _this.information.AdminId
        } else {
          _this.administration = adminId
        }
        _this.send({
          url: '/VillageListByPage',
          data: {
            AdminId: _this.administration,
            typeId: _this.typeId,
            page: _this.page,
            pageSize: _this.pageSize,
            key: _this.search,
            LawyerName: _this.LawyerName,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            sort: _this.sort
          },
          success: function (res) {
            _this.adminVillagesData = res.data
            _this.count = res.total
          }
        })
      }
      if (type == 2) {
        _this.modal11 = true
        _this.send({
          url: '/AdminUserList',
          loading: true,
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            page: _this.page,
            pageSize: _this.pageSize,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            VillagesId: _this.VillagesId,
            key: _this.userKey
          },
          success: function (res) {
            for (var i in res.data) {
              if(res.data[i].UserPhone){
                var mobile = res.data[i].UserPhone
                var tempStr = ''
                for (var j = 0; j < mobile.length; j++) {
                  if (j < 3 || j > mobile.length - 3) {
                    tempStr += mobile[j]
                  } else {
                    tempStr += '*'
                  }
                }
                res.data[i].UserPhone = tempStr
              }
            }
            _this.adminUserData = res.data
            _this.count = res.total
          }
        })
      }
      if (type == 3) {
        _this.modal15 = true
        _this.checkAllGroup = []
        _this.send({
          url: '/AdminLawyerList',
          loading: true,
          data: {
            AdminId: _this.information.AdminId,
            page: _this.page,
            pageSize: _this.pageSize,
            key: _this.searchLaw,
            isBinding:_this.isBinding
          },
          success: function (res) {
            _this.adminLawyerList = res.List
            for (var i in res.List) {
              var num = res.List[i].avgs
              console.log(Math.floor(num * 100) / 100)
            }
            _this.count = res.Count||0
            _this.BingCount = res.BingCount || 0
            _this.avgs = res.List.legend?res.List[0].avgs:0
            _this.sum = res.Sum || 0
          }
        })
        _this.send({
          url: '/AdminLawyerAvg',
          data: {
            AdminId: _this.information.AdminId,
            page: _this.page,
            pageSize: _this.pageSize,
            key: _this.searchLaw
          },
          success: function (res) {
            _this.lawyerAvg = res.avg
          }
        })
      }
      if (type == 4) {
        _this.modal3 = true
        // _this.send({
        //   url: '/OrderTypeCount',
        //   data: {
        //     AdminId: _this.information.AdminId,
        //     typeId: _this.typeId,
        //     start_time: _this.startTime,//开始时间
        //     end_time: _this.endTime
        //   },
        //   success: function (res) {
        //     _this.orderTypeCountData = res
        //   }
        // })
      }
      if (type == 6) {
        this.$router.push({ name: 'MAL' })
      }
      if (type == 'kq') {
        this.$router.push({ path: '/punchIn' })
      }
      if (type == 5) {
        _this.effect = index
        if (_this.effect == 1) {
          _this.processingStatus()
          _this.send({
            url: '/UserOrderLIst',
            loading: true,
            data: {
              AdminId: _this.information.AdminId,
              start_time: _this.startTime, //开始时间
              end_time: _this.endTime, //结束时间
              nameKeyId: _this.nameKeyId, //关键字
              page: _this.page,
              pageSize: _this.pageSize,
              VillagesId: _this.VillagesId,
              IsReply: _this.IsReply,
              isArchive : _this.isArchive,
            },
            success: function (res) {
              for (var i in res.list) {
                if (res.list[i].jsonContent) {
                  for (var k in res.list[i].jsonContent) {
                    if (JSON.parse(res.list[i].jsonContent[k]).type == 1) {
                      console.log(JSON.parse(res.list[i].jsonContent[k]))
                      var time = JSON.parse(res.list[i].jsonContent[k])
                          .sendTime.replace(/年|月/g, '-')
                          .replace('日', '')
                      res.list[i].handleTime = time.substr(0, time.length - 3)
                      break
                    } else {
                      res.list[i].handleTime = '--'
                    }
                  }
                } else {
                  res.list[i].handleTime = '--'
                }
              }
              _this.UserOrderLIst = res.list
              _this.count = res.count
            }
          })
        }
        if (index == 2) {
          _this.processingStatus()
          _this.send({
            url: '/UserOrderLIst',
            loading: true,
            data: {
              AdminId: _this.information.AdminId,
              start_time: _this.startTime, //开始时间
              end_time: _this.endTime, //结束时间
              nameKeyId: _this.nameKeyId, //关键字
              page: _this.page,
              pageSize: _this.pageSize,
              VillagesId: _this.VillagesId,
              IsReply: _this.IsReply,
              isValid : '1',//随便传
              isArchive : _this.isArchive,
            },
            success: function (res) {
              for (var i in res.list) {
                for (var k in res.list[i].jsonContent) {
                  if (JSON.parse(res.list[i].jsonContent[k]).type == 1) {
                    console.log(JSON.parse(res.list[i].jsonContent[k]))
                    var time = JSON.parse(res.list[i].jsonContent[k])
                        .sendTime.replace(/年|月/g, '-')
                        .replace('日', '')
                    res.list[i].handleTime = time.substr(0, time.length - 3)
                    break
                  } else {
                    res.list[i].handleTime = '--'
                  }
                }
              }
              _this.UserOrderLIst = res.list
              _this.count = res.count
            }
          })
        }

        // 详情
      }
    },
    replace: function () {
      var _this = this
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/getVillageSunCode'
      } else {
        var url = '/CD_getVillageSunCode'
      }
      var op = _this.information.OrganizationId + 'p' + _this.lawyerPhone + 'p' + 1
      _this.send({
        url: url,
        consulting1:true,
        responseType:"arraybuffer",
        data: {
          OrganizationId: _this.information.OrganizationId,
          scene: op,
          userType: 2,
          page: _this.pageUrl,
          width: '430',
          jpg: 'jpg',
          LawyerName: _this.lawyerName,
          LawyerPhone: _this.lawyerPhone,
          LawyerId: _this.lawyerId
        },
        success: function (res) {
          _this.listData = []
          ;(_this.lawyerName = ''), (_this.lawyerPhone = ''), (_this.page = '1')
          _this.lawList()
        }
      })
    },
    deleteLawFirm: function (adminid, organizationId) {
      var _this = this
      _this.send({
        url: '/deleteMember',
        confirm: true,
        data: {
          AdminId: adminid,
          Level: _this.information.Level,
          OrganizationId: organizationId
        },
        success: function (res) {
          console.log(res)
          _this.listData = []
          _this.page = '1'
          _this.adminList()
        }
      })
    },
    sendOut: function () {
      var _this = this
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/SendMessage'
      } else {
        var url = '/CD_SendMessage'
      }
      _this.send({
        url: url,
        data: {
          LawyerId: _this.lawyerId1,
          LawyerPhone: _this.lLawyerPhone
        },
        success: function (res) {
          if (res.Code == 'OK') {
            _this.$Notice.warning({
              title: '系统提示',
              desc: '发送成功'
            })
          }
        }
      })
    },
    //    地区
    getAreaData: function () {
      var _this = this
      _this.send({
        url: '/GetAreaData',
        data: {
          AreaId: _this.information.AreaId
        },
        success: function (res) {
          //
          _this.cityList = res[1]
          console.log(_this.cityList)

          //
          convertTree(res[1])
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let { AreaId: value, AreaName: label, children: children } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                children
              })
            })
            _this.addressData = result
            return result
          }
          console.log(_this.addressData)
        }
      })
    },
    choice: function (value) {
      var _this = this
      var data = value.value.split(',')
      console.log(data)
      _this.AreaId = data[0]
      if (data[2] == '1') {
        _this.areaData = _this.cityList[data[1]].children
      } else {
      }
      console.log()
      _this.send({
        url: '/GetfileList',
        data: {
          AreaId: data[0],
          AdminId: _this.information.AdminId
        },
        success: function (res) {
          console.log(res)
          var ElectronicsList = []
          _this.ElectronicsList = res
        }
      })
    },
    oncliTime: function (e) {
      console.log(e)
      var _this = this
      ;(_this.start_time = e[0]), //开始时间
          (_this.end_time = e[1]) //结束时间
      _this.page = 1
      _this.orderTypeCount(5, undefined, _this.effect)
    },
    totalTime: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.page = 1
      _this.switchData(0)
    },
    totalTime3: function (e) {
      var _this = this
      ;(_this.startTime = e[0]), //开始时间
          (_this.endTime = e[1]) //结束时间
      _this.page = 1
      _this.orderTypeCount(2)
    },
    searchDara: function (type) {
      var _this = this
      if (type == 1) {
        _this.search = _this.searchData
        _this.LawyerName = ''
      } else {
        _this.search = ''
        _this.LawyerName = _this.searchData
      }
      _this.adminVillagesData = []
      _this.count = ''
      _this.page = '1'
      _this.orderTypeCount(1)
    },
    //    排序
    sortClick: function (e) {
      var _this = this
      _this.adminVillagesData = []
      _this.count = ''
      _this.page = '1'
      _this.sort = e
      _this.orderTypeCount(1)
      console.log(this.sort)
    },
    remoteMethod2(query) {
      console.log(query)
      var _this = this
      _this.send({
        url: '/villagesList',
        data: {
          AdminId: _this.information.AdminId,
          Key: query
        },
        success: function (res) {
          console.log(res)
          _this.options2 = res.list
        }
      })
    },
    lawyerClick3: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.orderTypeCount(2)
      console.log(e)
    },

    //    10日统计
    getTenData: function () {
      var _this = this
      let myChartGetTen = echarts.init(document.getElementById('myChartGetTen'), 'light')
      _this.send({
        url: '/GetTenData',
        data: {
          AdminId: _this.information.AdminId
        },
        success: function (res) {
          _this.getTen_Data = res.list.day
          var time = [] //底部日期
          var Aid_count = [] //
          var Self_count = [] //
          var video_count = [] //
          var Moni_count = [] //
          var Daixie_count = [] //
          var Legal_count = [] //
          var Banli_count = [] //
          var Tiaojie_count = [] //
          var Phone_count = [] //
          var Rexian_count = [] //
          var Ming_count = [] //
          for (var i=res.list.day.length-1;i>=0;i--) {
            time.push(res.list.day[i].time)
            time.splice(12, 12)
            Aid_count.push(res.list.day[i].Aid_count)
            Aid_count.splice(12, 12)
            Self_count.push(res.list.day[i].Self_count)
            Self_count.splice(12, 12)
            video_count.push(res.list.day[i].video_count)
            video_count.splice(12, 12)
            Moni_count.push(res.list.day[i].Moni_count)
            Moni_count.splice(12, 12)
            Daixie_count.push(res.list.day[i].Daixie_count)
            Daixie_count.splice(12, 12)
            Legal_count.push(res.list.day[i].Legal_count)
            Legal_count.splice(12, 12)
            Banli_count.push(res.list.day[i].Banli_count)
            Banli_count.splice(12, 12)
            Tiaojie_count.push(res.list.day[i].Tiaojie_count)
            Tiaojie_count.splice(12, 12)
            Phone_count.push(res.list.day[i].Phone_count)
            Phone_count.splice(12, 12)
            Rexian_count.push(res.list.day[i].Rexian_count)
            Rexian_count.splice(12, 12)
            Ming_count.push(res.list.day[i].Ming_count)
            Ming_count.splice(12, 12)
          }
          console.log(Aid_count, time)

          // 绘制图表
          var datanums = []
          var visit = []
          myChartGetTen.setOption({
            title: {
              text: ''
            },
            tooltip: {
              trigger: 'axis',
              // hideDelay: '10000',
              axisPointer: {
                type: 'cross'
              }
            },
            legend: {
              data: [
                '人工咨询',
                '自助咨询',
                '视频咨询',
                '模拟判决',
                '文书下载',
                '法律援助',
                '办理公证',
                '调解申请',
                '律师电话',
                '拨打12348热线',
                '民法典'
              ]
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              name: '日期',
              type: 'category',
              boundaryGap: false,
              //时间显示
              data: time
            },
            yAxis: {
              name: '数',
              type: 'value'
            },
            series: [
              {
                name: '人工咨询',
                type: 'line',
                data: Aid_count
              },
              {
                name: '自助咨询',
                type: 'line',
                data: Self_count
              },
              {
                name: '视频咨询',
                type: 'line',
                data: video_count
              },
              {
                name: '模拟判决',
                type: 'line',
                data: Moni_count
              },
              {
                name: '文书下载',
                type: 'line',
                data: Daixie_count
              },
              {
                name: '法律援助',
                type: 'line',
                data: Legal_count
              },
              {
                name: '办理公证',
                type: 'line',
                data: Banli_count
              },
              {
                name: '调解申请',
                type: 'line',
                data: Tiaojie_count
              },
              {
                name: '律师电话',
                type: 'line',
                data: Phone_count
              },
              {
                name: '拨打12348热线',
                type: 'line',
                data: Rexian_count
              },
              {
                name: '民法典',
                type: 'line',
                data: Ming_count
              }
            ]
          })
        }
      })
    },
    jurisdiction: function () {
      var _this = this
      _this.$Spin.show()
      _this.send({
        url: '/IndexDifficultCount',
        data: {
          AdminId: window.sessionStorage.AdminId
        },
        success: function (res) {
          _this.$Spin.hide()
          _this.numberofStatesshow = false
          var shiju_count = 0
          var quxian_count = 0
          var sfs_count = 0
          var Villages_count = 0
          var Lawyers_count = 0
          for(var i in res.list){
            shiju_count += res.list[i].shiju_count
            quxian_count += res.list[i].quxian_count
            sfs_count += res.list[i].sfs_count
            Villages_count += res.list[i].Villages_count
            Lawyers_count += res.list[i].Lawyers_count
            var data =
                {
                  99999999:{
                    AdminName : '总数',
                    shiju_count :  shiju_count,
                    quxian_count : quxian_count,
                    sfs_count : sfs_count,
                    Villages_count : Villages_count,
                    Lawyers_count : Lawyers_count,
                  }
                }
          }
          _this.difficultCount = {...data,...res.list}
          _this.count1 = res.count
          _this.countData = res.count
          console.log(res)
        }
      })
    },
    serviceClick: function (LawyerId) {
      var _this = this
      _this.send({
        url: '/GetLawyerAdminCount',
        data: {
          LawyerId: LawyerId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId
        },
        success: function (res) {
          _this.statisticsData1 = res
          console.log(res)
        }
      })
      _this.send({
        url: '/LawyerOrderAdminCount',
        data: {
          LawyerId: LawyerId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId
        },
        success: function (res) {
          _this.lawyerOrderTypeData = res
        }
      })
      _this.send({
        url: '/lawyerAdminVillages',
        data: {
          LawyerId: LawyerId,
          start_time: _this.startTime, //开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId
        },
        success: function (res) {
          _this.lawyerVillagesData = res
        }
      })
    },
    uploadDate: function (source, type) {
      var date = new Date(1000 * source) //获取一个时间对象
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var H = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var B = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ' '
      if (type == 1) {
        return Y + M + D + H + B
      }
      if (type == 2) {
        return Y + M + D
      }
    }
  },
  computed: {
    areaId: function () {
      return window.sessionStorage.AreaId
    }
  }
}
</script>
