<template>
  <div>
    <div class="screen">
      <div style="background-color: white;">
        <div style="padding:15px"></div>
        <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 行政执法监督</div>
        <div style="padding:15px"></div>
        <div class="publicWelfare" >
          <table class="formtable">
            <thead>
            <tr>
              <th>序号</th>
              <th>用户</th>
              <th>联系方式</th>
              <th>监督建议内容</th>
              <th>回复状态</th>
              <th>提交时间</th>
              <th>回复时间</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(value,index) in superviseData.data">
              <td>{{index+1}}</td>
              <td><Avatar :src="value.user.UserImg" /> {{ value.user.UserName }}</td>
              <td>{{value.user.UserPhone}}</td>
              <td>{{value.apply_content}}</td>
              <td>
                <template v-if="value.reply_status == 0"><Tag color="orange">未回复</Tag></template>
                <template v-if="value.reply_status == 1"><Tag color="green">已回复</Tag></template>
              </td>
              <td>
                {{value.create_at}}
              </td>
              <td>
                {{value.reply_time||'--'}}
              </td>
              <td>
<!--                <span @click=";(dataPanel.data = value), (dataPanel.state = true)" style="cursor: pointer;color:#2d8cf0;">查看</span>-->
<!--                <span style="padding:3px;"></span>-->
                <span @click="onreply(value)" :style="{ cursor: 'pointer', color: value.reply_status == 0 ? '#f90' : '#2d8cf0' }">{{value.reply_status == 0?'回复':'查看'}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--平台回复-->
    <Modal v-model="replyShow" title="平台回复" @on-ok="submit" ok-text="发送" style="height: 300px">
      <Input v-if="onreplyData.reply_status == 0"
          v-model="PlatformApply" :autosize="true"
          maxlength="300"
          show-word-limit
          type="textarea"
          placeholder="回复内容"
      />
      <div v-if="onreplyData.reply_status == 1">
        回复内容：{{onreplyData.reply_content}}
      </div>
      <template v-slot:footer>
        <Button @click="replyShow = false">取消</Button>
        <Button type="primary" @click="submit" v-if="onreplyData.reply_status == 0">发送</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "supervise",
  data(){
    return{
      superviseData: {
        data: [],
      },
      replyShow:false,
      onreplyData: [],
      PlatformApply: ''
    }
  },
  methods:{
    // 获取运行报告列表
    supervisionAdminList: function (){
      var _this = this
      _this.send({
        url : '/supervisionAdminList',
        data: {
          AdminId : window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.superviseData.data = res.list
        }
      })
    },
    onreply: function (value){
      this.replyShow = true
      this.onreplyData = value
    },
    submit: function (){
      var _this = this
      _this.send({
        url : '/supervisionReply',
        data: {
          AdminId : window.sessionStorage.AdminId,
          id:_this.onreplyData.id,
          content:_this.PlatformApply
        },
        success: function (res) {
          _this.PlatformApply = ''
          _this.replyShow = false
          _this.supervisionAdminList()
        }
      })
    },
  },
  mounted() {
    this.supervisionAdminList()
  },
}
</script>

<style scoped>
.screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
.screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
.screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
.publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
.formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
.publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
.publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
.details p{color: #000000}
.details span{color: #5c6b77}
.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

</style>
