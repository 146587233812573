<style scoped>
table,tr,th,td{ border:1px solid #000000;padding:12px 10px;}
*{font-family:SimSun;}
</style>

<template>
  <Modal v-model="ownState" width="750px" :closable="false" :styles="{top:'7%'}">
    <table style="border-collapse: collapse;border-spacing:0;width: 100%;" v-if="JSON.stringify(tableData)!='{}'">
      <thead>
      <tr>
        <td colspan="6" style="text-align: center;font-size:24px;font-weight:bold;">法律援助申请表</td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>申请类别</td>
        <td colspan="5">{{tableData.aid_context.lawHelperType}}</td>
      </tr>
      <tr>
        <td colspan="6" style="font-size:18px;font-weight:bold;">基本信息</td>
      </tr>
      <tr>
        <td>申请人</td>
        <td>{{tableData.aid_context.name}}</td>
        <td>性别</td>
        <td>{{tableData.aid_context.sex}}</td>
        <td>民族</td>
        <td>{{tableData.aid_context.nationality}}</td>
      </tr>
      <tr>
        <td>身份证号码</td>
        <td>{{tableData.aid_context.idCard}}</td>
        <td>年龄</td>
        <td>{{tableData.aid_context.age}}</td>
        <td>联系方式</td>
        <td>{{tableData.aid_context.phoneNum}}</td>
      </tr>
      <tr>
        <td>出生日期</td>
        <td colspan="2">{{tableData.aid_context.birthDate}}</td>
        <td>申请时间</td>
        <td colspan="2">{{tableData.created_at}}</td>
      </tr>
      <tr>
        <td>身份证明</td>
        <td colspan="5">
          <a :href="tableData.aid_context.idCardFrontUrl" target="blank"><img height="140px" :src="tableData.aid_context.idCardFrontUrl" style="border:5px solid #e5e5e5;"/></a>
          <span style="padding:5px;"></span>
          <a :href="tableData.aid_context.idCardBackUrl" target="blank"><img height="140px" :src="tableData.aid_context.idCardBackUrl" style="border:5px solid #e5e5e5;"/></a>
        </td>
      </tr>
      </tbody>
    </table>
    <div slot="footer">
      <Button type="warning" long @click="ownState=false"><Icon type="ios-alert-outline"/> 关闭</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name:'legalaidModalhhht',
  props:{
    tableData:{
      type:Object,
      default:()=>{return {}},
    },
    state:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    state:function(val){
      this.ownState = val
    },
    ownState:function(val){
      this.$emit('on-close',val)
    },
  },
  data() {
    return{
      ownState:false
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
