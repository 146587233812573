<template>
  <div>
    <Row :gutter="16">
      <Col span="14">
        <div style="padding: 18px 20px;">角色列表</div>
        <Table :columns="roleColumns" :data="roleList" row-key="AdminId" @on-row-click="singleLineClick" highlight-row border>
          <!--      <template slot-scope="{ row }" slot="name">-->
          <!--        <strong>{{ row.name }}1</strong>-->
          <!--      </template>-->
        </Table>
      </Col>
      <Col span="10">
        <div style="padding: 18px 20px;">菜单分配</div>
        <Table :columns="navigationColumns" :data="navigationBar" row-key="id" >
          <template slot-scope="{ row, index }" slot="action">
            <Checkbox ></Checkbox>
          </template>
        </Table>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "roles",
  data () {
    return {
      // 角色数据
      roleList: [], // 表格数据
      roleColumns: [ // 表格列定义
        { title: '名称', key: 'AdminName', tree: true },
        // { title: '菜单地址', key: 'url' },
        { title: 'ID', key: 'AdminId' },
      ],
      // 导航栏数据
      navigationBar: [], // 表格数据
      navigationColumns: [ // 表格列定义
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        },
        { title: '菜单标题', key: 'name', tree: true },
        { title: 'ID', key: 'id' },
      ],
      index : 1
    }
  },
  methods:{
    // 获取当前角色消息
    rolePerMissionList: function(){
      var _this = this;
      this.send({
        url: '/TotalAdminList',
        data: {
          AdminId: 0,
          page: '',
          pageSize: '',
        },
        success: (res) => {
          console.log(res);
          _this.roleList = _this.replaceChildren(res.list);
          console.log(_this.roleList,'AAAAA');
        }
      });
    },
    // 当前角色转数据
    replaceChildren: function(data) {
      return data.map(item => {
        // 创建一个新对象，只包含需要的属性
        const newItem = {
          AdminName: item.AdminName,
          Level: item.Level,
          AdminId: item.AdminId,
          roleId: item.roleId,
          index: this.index++,
          children: item.list&&item.Level <= 1 ? this.replaceChildren(item.list) : [] // 递归处理子项
        };
        return newItem;
      });
    },
    // 点击单行数据
    singleLineClick: function (data,index){
      console.log(data,index)
      var _this = this
      this.send({
        url : '/RolePerMissionList',
        data: {id:data.roleId},
        success: function (res) {
          function convertData(data) {
            let newItemData = [];
            data.forEach(item => {
              // 创建一个新对象，只包含需要的属性
              const newItem = {
                name: item.name,
                id: item.id,
              };
              // 如果 item 有 get_tree 属性，递归调用 convertData 并将结果添加到 newItemData 中
              if (item.get_tree) {
                newItemData = newItemData.concat(convertData(item.get_tree));
              }
              newItemData.push(newItem);
            });
            return newItemData;
          }
          const result = convertData(res[0].pression);
          console.log(result)
        }
      })
    },
    // 获取菜单列表
    permissionList: function(){
      var _this = this
      this.send({
        url : '/permissionList',
        data: {},
        success: function (res) {
          _this.navigationBar = _this.menuData(res)
        }
      })
    },
    // 菜单数据
    menuData: function(data) {
      return data.map(item => {
        // 创建一个新对象，只包含需要的属性
        const newItem = {
          name: item.name,
          id: item.id,
          children: item.get_tree ? this.menuData(item.get_tree) : [] // 递归处理子项
        };
        return newItem;
      });
    },
  },
  mounted() {
    this.rolePerMissionList()
    this.permissionList()
  }
}
</script>

<style scoped>

</style>
