<template>
  <div class="screen">
    <div style="background-color: white;margin-bottom: 30px">
      <div style="padding: 15px"></div>
      <div style="
                    font-size: 16px;
                    text-align: left;
                    margin-left: 20px;
                    border-left: solid #de4f0b 3px;
                    padding-left: 5px;
                ">工作建议</div>
    </div>
    <div class="publicWelfare" style="margin: 0 20px">
      <table class="formtable">
        <thead>
        <tr>
          <th>法律明白人</th>
          <th>手机号</th>
          <th>反馈时间</th>
          <th>反馈内容</th>
          <th>是否回复</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, index) in opinionData.data">
          <td nowrap="nowrap">
            <Avatar :src="value.insider.InsiderImg" />
            <div style="margin-top: 4px">{{ value.insider.InsiderName }}</div>
          </td>
          <td>{{ value.insider.InsiderPhone }}</td>
          <td>{{ value.created_at }}</td>
          <td>{{ value.advice_msg }}</td>
          <td v-if="value.reply_msg">
            <Button type="primary" @click="onreply(value.reply_msg)">已回复</Button>
<!--            <div>回复时间：{{ value.replyTime }}</div>-->
          </td>
          <td v-else="">未回复</td>
          <td>
            <Button type="primary" @click="reply(value.id, 1, value.reply_msg)"
            >回复操作</Button
            >
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="text-align: left; margin: 30px; font-size: 16px">
      <Page :total="opinionData.total" :page-size="opinionData.pageSize" show-elevator show-total
            @on-change="opinionData.page = $event;adviceList()" />
    </div>
    <!--回复-->
    <Modal v-model="opinionData.replyShow" title="回复" @on-ok="submit" ok-text="发送" style="height: 300px">
      <Input v-model="opinionData.content" maxlength="300" show-word-limit type="textarea" placeholder="回复内容" />
    </Modal>
<!--    查看-->
    <Modal v-model="opinionData.viewShow" title="回复内容" ok-text="关闭" cancel-text="">
      <div class="details">
        {{ opinionData.replydata }}
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
name: "recommendation",
  data() {
    return {
      opinionData : {
        data : [],
        total: 0,
        pageSize: 10,
        page: 1,
        replyShow : false,
        viewShow : false,
        id : 0,
        content : '',
        type : '',
        replydata : '',
      },
      information : [],
    }
  },
  mounted() {
    this.information = window.sessionStorage
    this.adviceList()
  },
  methods: {
    onreply: function (reply) {
      this.opinionData.replydata = reply
      this.opinionData.viewShow = !this.opinionData.viewShow
    },
    submit: function () {
      var _this = this
      _this.send({
        url: '/AdminAdviceById',
        data: {
          AdminId: _this.information.AdminId,
          msg: _this.opinionData.content,
          id: _this.opinionData.id
        },
        success: function (res) {
          console.log(res)
          _this.$Notice.success({
            title: '系统提示',
            desc: '发送成功'
          })
          _this.adviceList(_this.type)
        }
      })
    },
    reply: function (id, e, reply) {
      var _this = this
      _this.opinionData.replyShow = !_this.opinionData.replyShow
      _this.opinionData.id = id
      _this.opinionData.type = e
      _this.opinionData.content = reply
    },
    adviceList :function (){
      var _this = this
      _this.send({
        url: '/AdviceList',
        data: {
          page: _this.opinionData.page,
          pageSize: _this.opinionData.pageSize},
        success: function (res) {
            _this.opinionData.data = res.data
            _this.opinionData.total = res.total
        }
      })
    },
  }
}
</script>

<style scoped>
.screen :deep(.ivu-tabs-nav-scroll ){
  height: 60px;
  line-height: 60px;
}
/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav ){
  height: 60px;
  line-height: 60px;
}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active ){
  color: rgb(222, 79, 11);
  padding: 0px 16px;
}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover ){
  color: rgb(222, 79, 11);
}
.screen :deep(.ivu-tabs-ink-bar ){
  background-color: rgb(222, 79, 11);
}
.screen :deep(.ivu-tabs-bar ){
  border-bottom: 2px solid #d7d7d7;
  margin-bottom: 40px;
}
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}
.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
.details p {
  color: #000000;
}
.details span {
  color: #5c6b77;
}
.single {
  width: 100%;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  display: inline-block;
}
.contacts {
  font-size: 16px;
  color: #999999;
  display: inline-block;
  height: 66px;
  line-height: 66px;
}
.inputWord {
  color: #333333;
  font-size: 16px;
  border: 0;
  text-align: left;
  height: 66px;
  margin-left: 20px;
  width: 350px;
}
</style>
