//管理端路由模块加载器 Zima -- 2020-10-20
import nullPage from '@/views/nullPage'
import mdPage from '@/views/manager/mdPage'
import login from '@/views/manager/login'
import loginMbr from '@/views/loginMbr'
import loginGzy from '@/views/loginGzy'
import authRouter from '@/views/auth-router'
import home from './home'
import account from './account'
import mediation from './mediation'
import open from './open'
import dataCenter from './data-center'
import managerArchivesList from '@/views/manager/archives/list'

var routes = [
  {path: '*',component:login,meta:{title:'律蛙数字法治-智慧司法平台'}},
  {path: '/',component:mdPage,meta:{title:'律蛙数字法治-智慧司法平台'}},
  {path: '/manager/login', name:'managerLogin',component:login,meta:{title:'律蛙数字法治-智慧司法平台'}},
  {path: '/manager/loginMbr', name:'loginMbr',component:loginMbr,meta:{title:'律蛙数字法治-智慧司法平台'}},
  {path: '/manager/loginGzy', name:'loginGzy',component:loginGzy,meta:{title:'律蛙数字法治-智慧司法平台'}},
  {path: '/auth-router', name:'oneClickLogin',component:authRouter,meta:{title:'律蛙数字法治-智慧司法平台'}},
  {
    path:'/manager',
    component:mdPage,
    children:new Array().concat(
      home.routes,//首页路由
      account.routes,//账户管理路由
      mediation.routes,//账户管理路由
      {path: 'archives', name:'MAL',component:managerArchivesList,meta:{title:'律蛙数字法治-智慧司法平台'}}//档案管理
    )
  },
  {
    path:'/manager/data-center',
    component:nullPage,
    children:new Array().concat(
      dataCenter.routes,//数据中心路由
    )
  },
  {
    path:'/open',
    component:nullPage,
    children:new Array().concat(
      open.routes,//开放路由
    )
  },
]

export default {
  routes:routes
}
